<template>

  <PermissionGroupModal @sent="loadGroups()" ref="modal" />

  <div class="flex justify-between w-full">
    <h4 class="pagetitle">Permissions-Gruppen</h4>
    <div class="flex gap-2">
      <button v-show="this.$hasPerm('PERMISSION_EDIT')" @click='$refs.modal.show({name: "",sort: 0,permissionType: []})' class="newButton"><i class="fa-solid fa-plus"></i>
        Gruppe hinzufügen
      </button>
    </div>
  </div>


  <div class="card-content">


    <table class="min-w-full dark">
      <thead class=" bg-gray-700">
      <tr>
        <th scope="col"
            class="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-gray-400">
          Name
        </th>
        <th scope="col"
            class="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-gray-400">
          Sortierung
        </th>
        <th scope="col"
            class="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-gray-400">
          Berechtigungen
        </th>
        <th scope="col"
            class="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-gray-400">
          Bearbeiten
        </th>
      </tr>
      </thead>
      <tbody>

      <tr
          class="border-b bg-gray-800 border-gray-700"
          v-for="group in groups" :key="group.id"
      >
        <td :class="['py-4 px-6 text-sm text-gray-500 whitespace-nowrap']">
          {{ group.name }}
        </td>
        <td :class="['py-4 px-6 text-sm text-gray-500 whitespace-nowrap']">
          {{ group.sort }}
        </td>
        <td class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap flex gap-1 flex-wrap">
          <span v-for="perm in group.permissionType" :key="perm" class="bg-gray-900 p-1 rounded">
            <i v-show='perm === "OFFICER_EDIT_EDIT_RANK" || perm === "PERMISSION_EDIT"' class="fa-solid fa-circle-exclamation text-red-500"></i> {{ $t("permission." + perm) }}
          </span>
        </td>
        <td :class="['py-4 px-6 text-sm text-gray-500 whitespace-nowrap']">
          <button @click="$refs.modal.show(group)" class="btn"><i class="fa-solid fa-pen"></i></button>
        </td>
      </tr>

      </tbody>
    </table>


  </div>


  <div v-show="this.$hasPerm('GLOBALSETTINGS_DISCORD')">

    <div class="flex justify-between w-full mt-10">
      <h4 class="pagetitle">Discord-Secret</h4>
    </div>
    <div class="p-2 w-full">
      <div class="newcard p-5 w-full">
        <div>
          <h4 class="text-xl">Discord-Secret</h4>
            <input id="badge"
                   disabled
                   v-model="secret"
                   type="text"
                   class="block w-full px-4 py-2 mt-2 text-gray-700 border border-gray-200 rounded-md bg-gray-800 text-gray-300 border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 focus:border-blue-300 focus:outline-none focus:ring">
          <br>
          <button class="btn" @click="loadSecret"> Secret Laden</button>
        </div>

        <h3 class="text-xl mt-3">Verwendung</h3>
        <p>Wir bieten einen Discord-Bot an, welcher auf den Discord von deinem LSPD eingeladen werden kann. <br> Dieser hat aktuell folgende Funktionen:
        </p>
        <ul>
          <li>- Officer-Accounts erstellen (und per DM verschicken)</li>
          <li>- Logs im Discord</li>
          <li>- Ankündigungen von LSPDNet.com (Ankündigungen von Wartungsarbeiten, Changelogs, etc...)</li>
        </ul>
        <p>Für weitere Vorschläge für den Bot sind wir auf unserem Discord offen: <a href="https://discord.gg/lspdnet">discord.gg/lspdnet</a> </p>
        <p>Sobald der Bot auf dem Server ist, müsst ihr ihn mit /setup einrichten.</p>
        <a target="_blank" href="https://discord.com/api/oauth2/authorize?client_id=1034533801692512356&permissions=8&scope=bot"  class="btn mt-2">Bot Einladen</a>
      </div>
    </div>

  </div>

</template>

<script>
import PermissionGroupModal from "@/components/modals/PermissionGroupModal";
export default {
  name: "GlobalSettingsView",
  components: {PermissionGroupModal},
  data() {
    return {
      department: JSON.parse(localStorage.user).department,
      user: JSON.parse(localStorage.user),
      groups: [],
      secret: "Nicht geladen."
    }
  },
  methods: {
    loadGroups() {
      fetch(this.getAPIHostname() + "/department/" + this.department.id + "/permissions/",
          {
            method: "GET",
            mode: "cors",
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Basic ' + localStorage.auth
            },
          }
      ).then(this.jsonParser).then(json => {
        this.groups = json;
      })
    },
    loadSecret(){

      fetch(this.getAPIHostname() + "/department/" + this.department.id + "/secret/discord",
          {
            method: "GET",
            mode: "cors",
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Basic ' + localStorage.auth
            },
          }
      ).then(value => {
        return value.text();
      }).then(text => {
        this.secret = text;
      })
    }
  },
  mounted() {
    this.loadGroups()
  }

}
</script>

<style scoped>

</style>
