<template>

  <div class="card">
    <div class="title">Aktivste Officer (7 Tage)</div>
    <hr>
    <div class="card-content">


      <table class="min-w-full dark table-fixed">
        <thead class=" bg-gray-700">
        <tr>
          <th scope="col"
              class="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-gray-400">
          {{ $t('stats.badge') }}
          </th>
          <th scope="col"
              class="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-gray-400">
            Name
          </th>
          <th scope="col"
              class="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-gray-400">
            Rank
          </th>
        </tr>
        </thead>
        <tbody>
        <tr
            v-for="entry in list"
            :key="entry.id"
            class="border-b bg-gray-800 border-gray-700"
            @click="openOfficer(entry.badgeNumber)">

          <td class="py-4 px-6 text-sm text-gray-500">{{entry.badgeNumber}}</td>
          <td class="py-4 px-6 text-sm text-gray-500">{{entry.name}}</td>
          <td class="py-4 px-6 text-sm text-gray-500">{{entry.permission}}</td>
        </tr>


        </tbody>
      </table>


    </div>
  </div>
</template>

<script>
export default {
  name: "TopOfficerTable",
  data() {
    return {
      department: JSON.parse(localStorage.user).department,
      list: []
    }
  },
  methods: {
    loadData() {
      fetch(this.getAPIHostname() + "/department/" + this.department.id + "/stats/topUser",
          {
            method: "GET",
            mode: "cors",
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Basic ' + localStorage.auth
            },
          }
      )
          .then(this.jsonParser)
          .then(
              json => {
                this.list = json;
              }
          )
    },
    openOfficer(id){
      window.location.href = "copuser/" + id
    }
  },
  mounted() {
    this.loadData();
  }
}
</script>

<style scoped>

</style>
