<template>


  <div class="inputWrapper flex flex-wrap items-center p-1" @click="focusInput">
    <div class="flex selectedItem items-center" v-for="item in output" :key="item.value">
      <span>{{ item.name }}</span>
      <button type="button" @click="output = output.filter(e => e !== item); generateList(); $emit('updated');">
        <i class="fa-solid fa-x"></i></button>
    </div>

    <div class="inputContainer grow">
      <input autocomplete="off"
             @focusin="setShow(true)"
             @focusout="setShow(false)"
             @keyup="check"
             @keydown="keydown"
             v-model="currentIn"
             id="theInput"
             type="text">

      <div class="completeContainer">
        <button v-show="showPrompt"
             v-for="(string,index) in displayedList"
             @click="setInput(string)"
             :key="string"
             tabindex
             :class="['completer', index === selected ? 'selected' : '']">
          {{ string }}
        </button>
      </div>
    </div>
  </div>

</template>
<script>


export default {
  name: "MultiSelectInput",
  emits: ['updated'],
  data() {
    return {
      output: [],
      currentIn: "",
      displayedList: [],
      showPrompt: false,
      selected: 0
    }
  },
  props: {
    list: [],
    out: [],
  },
  mounted() {
    this.output = this.out;
    this.generateList();
  },
  methods: {
    check() {
      for (let i = 0; i < this.list.length; i++) {
        if (this.list[i].name.toLowerCase() === this.currentIn.toLowerCase()) {
          this.output.push(this.list[i]);
          this.currentIn = '';
          this.$emit('updated')
          this.generateList();
          break;
        }
      }
    },
    generateList() {
      console.log("yes")

      this.displayedList = this.list.filter(o => {
        return !this.output.includes(o)
      })
          .map(o => o.name)
          .filter(o => {
            return o.toUpperCase().includes(this.currentIn.toUpperCase())
          })
      this.selected = 0;

    },

    focusInput() {
      let input = document.getElementById('theInput');
      input.focus();
    },

    setInput(s) {
      console.log(s)
      this.currentIn = s;
      this.check();
    },
    setShow(b) {
      setTimeout(() => this.showPrompt = b, 100)
    },
    keydown(event){
      if(event.key === "Enter"){
        event.preventDefault();
        if(this.displayedList.length > this.selected && this.selected >= 0){
          this.currentIn = this.displayedList[this.selected];
        }
      }else if(event.key === "ArrowDown"){
        event.preventDefault();
        if(this.displayedList.length > this.selected + 1) this.selected++;
      }else if(event.key === "ArrowUp"){
        event.preventDefault();
        if(this.selected > 0) this.selected--;
      }else {
        this.generateList();
      }


    }

  }
}
</script>

<style scoped>

.completeContainer {
  position: absolute;
  z-index: 60;
  background-color: var(--bg1);
  outline: white 1px solid;
  border-radius: 0.5rem;
  max-height: 10em;
  width: 60%;
  overflow-y: scroll;
}

.completer {
  text-align: start;
  width: 100%;
  padding: 0.5rem;
  transition: 100ms;
  border-bottom: gray 1px solid;
}

.completer:hover {
  color: var(--red);
}
.completer.selected {
  color: var(--red);
}

.inputWrapper {
  border: 1px white solid;
  border-radius: 5px;
  height: auto;
  width: auto;
}

.inputContainer {
  width: 100%;
  height: fit-content;
  position: static;
}

input {
  background-color: transparent;
  border: none;
  height: 2em;
  width: auto;
}

input:focus {
  border: none;
  outline: none;
}

.inputWrapper:focus-within {
  outline: 4px solid rgb(147 197 253 / .4);
}

.selectedItem {
  height: 2em;
  margin: 0.5rem;
  background-color: var(--bg2);
}

.selectedItem span {
  padding: 0.5em;
}

.selectedItem button {
  height: 100%;
  padding: 0.5em;
  transition: 300ms;
  font-weight: bold;
  font-size: 0.75rem;
  background-color: rgba(73, 73, 50, 0.27);
}

.selectedItem button:hover {
  color: var(--red)
}

.selectedItem button:focus {
  border: none;
  outline: none;
}

</style>
