<template>
  <div v-show="showModal" class="fixed z-20 inset-0 overflow-y-auto " aria-labelledby="modal-title" role="dialog"
       aria-modal="true">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 ">
      <div class="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
      <div id="popup"
           class="inline-block align-bottom dark bg-gray-800 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
        <div class=" px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div class="sm:flex sm:items-start">
            <div
                class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-gray-900 sm:mx-0 sm:h-10 sm:w-10">
              <i class="fa fa-plus-circle"></i>
            </div>
            <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <h3 class="text-lg leading-6 font-medium " id="modal-title">
                Add Officer-Account
              </h3>
              <div class="mt-2">
                <div class="flex flex-col mt-8 space-y-3 sm:space-y-0 sm:flex-row sm:justify-start sm:-mx-2">

                  <form>
                    <div class="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">

                      <div>
                        <label class="text-gray-200" for="badge">Badge</label>
                        <input id="badge"
                               v-model="copuser.badgeNumber"
                               type="text"
                               class="block w-full px-4 py-2 mt-2 text-gray-700 border border-gray-200 rounded-md bg-gray-800 text-gray-300 border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 focus:border-blue-300 focus:outline-none focus:ring">
                      </div>

                      <div>
                        <label class="text-gray-200" for="loginUsername">Name</label>
                        <input id="loginUsername"
                               v-model="copuser.name"
                               type="text"
                               class="block w-full px-4 py-2 mt-2 text-gray-700 border border-gray-200 rounded-md bg-gray-800 text-gray-300 border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 focus:border-blue-300 focus:outline-none focus:ring">
                      </div>

                      <div>
                        <label class="text-gray-200">Rang</label>
                        <select
                            v-model="copuser.permissionGroup.id"
                            type="text"
                            class="block w-full px-4 py-2 mt-2 text-gray-700 border border-gray-200 rounded-md bg-gray-800 text-gray-300 border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 focus:border-blue-300 focus:outline-none focus:ring">

                          <option v-for="group in groups" :key="group.id" :value="group.id" @click="console.log(group.name)">
                            {{ group.name }}
                          </option>
                        </select>
                      </div>


                    </div>
                  </form>

                  <div v-show="showInfo">
                    <center>
                      <h3>Anmeldedaten:</h3>
                    <b>Username:</b>{{department.id + "-" + copuser.badgeNumber}}<br>
                    <b>Passwort:</b>{{copuser.password}}<br>
                    <button  @click="close" class="btn">Read</button>
                    </center>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <button @click="close" type="button"
                  class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2  text-base font-medium hover:text-gray-900  hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
            Abort
          </button>
          <button @click="clicked" type="button"
                  class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm">
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: "AddCopUserModal",
  data() {
    return {
      showModal: false,
      showInfo: false,
      department: JSON.parse(localStorage.user).department,
      copuser: {
        badgeNumber: "",
        name: "",
        password: "",
        permissionGroup: {id: null},
      },
      groups: [],
    }
  },
  props: {
    admin: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.loadGroups();
  },
  methods: {
    show() {
      this.showModal = true
    },
    close(){
      this.showModal = false
      this.showInfo = false
    },
    makeid(length) {
      var result = '';
      var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
      }
      return result;
    },
    clicked() {
      this.copuser.password = this.makeid(10)
      if(this.admin){
        this.copuser.permission = "CHIEF"
      }

      this.showInfo = true;

      fetch(this.getAPIHostname() + "/department/" + this.department.id + "/cop/",
          {
            method: "POST",
            mode: "cors",
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Basic ' + localStorage.auth
            },
            body: JSON.stringify(this.copuser)
          }
      ).then(this.jsonParser)
      .then(() => {
        this.$emit("sent")
      })
    },

    loadGroups() {
      fetch(this.getAPIHostname() + "/department/" + this.department.id + "/permissions/",
          {
            method: "GET",
            mode: "cors",
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Basic ' + localStorage.auth
            },
          }
      ).then(this.jsonParser).then(json => {
        this.groups = json;
      })
    }
  }
}
</script>

<style scoped>

</style>
