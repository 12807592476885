<template>

  <div class="p-5">
    <div class="flex flex-col items-center mb-10">
      <h1 class="text-red-500 font-extrabold my-4">OOC-Bereich</h1>
      <a class="text-white text-5xl font-extrabold" href="#"><img src="@/assets/img/Textlogo-Icon.svg" width="350" alt=""></a>
    </div>
    <AddCopUserModal ref="modal" @sent="loadChiefs" :admin=true />
    <div class="card">
      <div class="title-row">
        <div class="title">Department: {{department.name}}</div>
      </div>
      <hr>
      <div class="card-content">


        <form>
          <div class="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
            <div>
              <label class="text-gray-200" for="loginUsername">Name</label>
              <input id="loginUsername"
                     v-model="department.name"
                     type="text"
                     class="block w-full px-4 py-2 mt-2 text-gray-700 border border-gray-200 rounded-md bg-gray-800 text-gray-300 border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 focus:border-blue-300 focus:outline-none focus:ring">
            </div>


            <div>
              <label class="text-gray-200">Locale</label>
              <select
                  v-model="department.locale"
                  type="text"
                  class="block w-full px-4 py-2 mt-2 text-gray-700 border border-gray-200 rounded-md bg-gray-800 text-gray-300 border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 focus:border-blue-300 focus:outline-none focus:ring">
                <option>de</option>
                <option>en</option>
              </select>
            </div>

          </div>
          <div class="flex justify-end mt-6">
            <button @click="submit()"
                    type="button"
                    class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
              Submit
            </button>
          </div>
        </form>

      </div>
    </div>
    <div class="card">
      <div class="title-row flex flex-row justify-between">
        <div  class="title">Chief-Accounts</div> <button @click="openModal()" class="btn green m-2" >Add</button>
      </div>
      <hr>
      <div class="card-content">


        <table class="min-w-full dark">
          <thead class=" bg-gray-700">
          <tr>
            <th scope="col"
                class="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-gray-400">
              Badge
            </th>
            <th scope="col"
                class="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-gray-400">
              Name
            </th>
            <th scope="col"
                class="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-gray-400">
              Delete
            </th>
          </tr>
          </thead>
          <tbody>

          <tr
              class="border-b bg-gray-800 border-gray-700"
              v-for="chief in chiefs" :key="chief.id"
          >
            <td class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap text-white">
              {{ chief.badgeNumber }}
            </td>
            <td class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap text-white">
              {{  chief.name  }}
            </td>
            <td class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap text-white">
              <button @click="deleteChief(chief.badgeNumber)" class="btn">Delete</button>
            </td>
          </tr>
          </tbody>
        </table>



      </div>
    </div>

    <router-link to="/logout" class="pt-1 pb-2 text-red-500 text-bold font-bold">
      <i class="fa-solid fa-sign-out"></i> Logout
    </router-link>
  </div>

</template>

<script>
import AddCopUserModal from "@/components/AddCopUserModal";
export default {
  name: "DepartmentSettingsView",
  components: {AddCopUserModal},
  data() {
    return{
      department: JSON.parse(localStorage.user).department,
      chiefs: []
    }
  },
  methods: {
    submit(){
      fetch(this.getAPIHostname() + "/department/" + this.department.id + "/",
          {
            method: "PATCH",
            mode: "cors",
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Basic ' + localStorage.auth
            },
            body: JSON.stringify(this.department)
          }
      ).then(res => {
        if (res.status == 200) return res.json();
        else {
          this.$notify({
            title: "Error",
            text: " ",
            data: {
              type: 'error'
            }
          });
        }
      }).then(json => {
        this.department = json;
      })
    },
    openModal(){
      this.$refs.modal.show()
    },
    loadChiefs(){
      fetch(this.getAPIHostname() + "/department/" + this.department.id + "/cop/",
          {
            method: "GET",
            mode: "cors",
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Basic ' + localStorage.auth
            },
          }
      ).then(res => {
        if (res.status == 200){
          return res.json();
        }
        else {
          this.$notify({
            title: "Error",
            text: "Error while loading User-Data",
            data: {
              type: 'error'
            }
          });
        }
      }).then(json => {
        this.chiefs = json;
      })
    },
    deleteChief(badge){
      fetch(this.getAPIHostname() + "/department/" + this.department.id + "/cop/" + badge + "",
          {
            method: "DELETE",
            mode: "cors",
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Basic ' + localStorage.auth
            }
          }
      ).then(res => {
        if (res.status == 200){
          this.$notify({
            title: "Success",
            text: " ",
            data: {
              type: 'success'
            }
          });
        }
        else {
          this.$notify({
            title: "Error",
            text: " ",
            data: {
              type: 'error'
            }
          });
        }
      }).then(json => {
        this.department = json;
      })
    }
  },
  mounted() {
    this.loadChiefs();
  }
}
</script>

<style scoped>

</style>
