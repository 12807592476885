<template>
  <button class="staticButton" @click="openModal"><i class="fa-solid fa-plus"></i></button>
  <AddLawBookModal ref="modal" @sent="loadData"/>
  <AddLawModal ref="lawmodal" @sent="loadData"/>
  <ConfirmModal ref="deleteLaw" @yes="deleteLaw" title="Are you sure?" message="This deletes the Law. You cannot undo this!" />
  <ConfirmModal ref="deleteBook" @yes="deleteBook"  message="This deletes the lawbook and all its containing laws! You cannot undo this!"/>


  <div class="card" v-for="lawbook in lawbooks" :key="lawbook.id">
    <div class="title-row">
      <div class="title">{{ lawbook.name }}</div>
      <div class="flex flex-row gap-2">
        <button @click="$refs.deleteBook.show(lawbook)" v-show="this.$hasPerm('LAW_DELETE')" class="btn">{{ $t('lawview.delete') }}</button>
        <button @click="$refs.lawmodal.show(lawbook.id)" v-show="this.$hasPerm('LAW_ADD')" class="btn green">{{ $t('lawview.addbtn') }}</button>
      </div>
    </div>
    <hr>
    <div class="card-content">
      <div class="flex flex-col">
        <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="inline-block py-2 min-w-full sm:px-6 lg:px-8">
            <div class="overflow-hidden shadow-md sm:rounded-lg">

              <table class="min-w-full dark">
                <thead class=" bg-gray-700">
                <tr>
                  <th scope="col"
                      class="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-gray-400">
                    Name
                  </th>
                  <th scope="col"
                      class="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-gray-400">
                    Gefängnisstrafe
                  </th>
                  <th scope="col"
                      class="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-gray-400">
                    Geldstrafe
                  </th>
                  <th scope="col"
                      v-show="this.$hasPerm('LAW_DELETE')"
                      class="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-gray-400">
                    {{ $t('lawview.delete') }}
                  </th>
                </tr>
                </thead>
                <tbody>

                <tr
                    class="border-b bg-gray-800 border-gray-700 transition"
                    v-for="law in lawbook.laws"
                    :key="law.id"
                >
                  <td class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap text-gray-400">
                    {{ law.name }}
                  </td>
                  <td class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap text-gray-400 key ">
                    {{ law.prisonSentence }}
                  </td>
                  <td class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap text-gray-400">
                    {{ law.feeAmount }}$
                  </td>
                  <td v-show="this.$hasPerm('LAW_DELETE')" class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap text-gray-400">
                    <button @click="this.$refs.deleteLaw.show({
                    book: lawbook,
                    law: law
                    })" class="btn">{{ $t('lawview.delete') }}</button>
                  </td>
                </tr>


                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import AddLawBookModal from "@/components/AddLawBookModal";
import AddLawModal from "@/components/AddLawModal";
import ConfirmModal from "@/components/modals/ConfirmModal";

export default {
  name: "LawView",
  components: {
    ConfirmModal,
    AddLawBookModal,
    AddLawModal
  },
  data() {
    return {
      department: JSON.parse(localStorage.user).department,
      lawbooks: []
    }
  },
  methods: {
    openModal() {
      this.$refs.modal.show();
    },
    loadData() {

      fetch(this.getAPIHostname() + "/department/" + this.department.id + "/lawbook/",
          {
            method: "GET",
            mode: "cors",
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Basic ' + localStorage.auth
            },
          }
      ).then(res => {
        if (res.status == 200) {
          return res.json();
        } else if (res.status === 401) {
          this.$notify({
            title: "Error",
            text: "Invalid Login!",
            data: {
              type: 'error'
            }
          });
          localStorage.removeItem('auth')
        } else {
          this.$notify({
            title: "Error",
            text: " ",
            data: {
              type: 'error'
            }
          });
        }
      }).then(json => {
        this.lawbooks = json;
      })
    },
    deleteLaw(object) {
      let law = object.law;
      let lawBook = object.book;
      console.log(law)
      fetch(this.getAPIHostname() + "/department/" + this.department.id + "/lawbook/" + lawBook.id + "/" + law.id,
          {
            method: "DELETE",
            mode: "cors",
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Basic ' + localStorage.auth
            },
          }
      ).then(value => {
        if (value.status === 200) {
          this.$notify({
            title: "Success",
            text: "Law deleted!",
            data: {
              type: 'success'
            }
          });
        } else {
          this.$notify({
            title: "Error",
            text: "An error occurred!",
            data: {
              type: 'error'
            }
          });
        }

        this.loadData();

      })
    },
    deleteBook(lawBook) {
      fetch(this.getAPIHostname() + "/department/" + this.department.id + "/lawbook/" + lawBook.id ,
          {
            method: "DELETE",
            mode: "cors",
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Basic ' + localStorage.auth
            },
          }
      ).then(value => {
        if (value.status === 200) {
          this.$notify({
            title: "Success",
            text: "LawBook deleted!",
            data: {
              type: 'success'
            }
          });
        } else {
          this.$notify({
            title: "Error",
            text: "An error occurred!",
            data: {
              type: 'error'
            }
          });
        }

        this.loadData();

      })
    }
  },
  mounted() {
    this.loadData();
  }
}
</script>


<style scoped>

.staticButton {
  position: fixed;
  bottom: 3rem;
  right: 3rem;
  background-color: #59CD90;
  padding: 2rem;
  border-radius: 50%;
  transition: 500ms;
}

.staticButton:hover {
  transform: scale(1.2);
}


.title-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title-row > *:last-child {
  margin: 1rem;
}
</style>
