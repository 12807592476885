import {createApp} from 'vue'
import App from './App.vue'
import "@/assets/styles/main.css";
import router from './router'
import i18n from './i18n'
import Notifications from '@kyvg/vue3-notification'
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

let app = createApp(App).use(Notifications)
app.config.globalProperties.$hasPerm = function (permission) {
    if (localStorage.user == null) return false;
    else {
        let user = JSON.parse(localStorage.user);
        if (user.badgeNumber == null) return false;
        else {
            let permissions = user.permissionGroup.permissionType;
            return permissions.includes(permission);

        }
    }
}
app.config.globalProperties.jsonParser = function (res) {
    if (res.status === 200) {
        return res.json()
    } else if (res.status === 401) {
        app.config.globalProperties.$notify({
            title: "Error",
            text: "Login invalid!",
            data: {
                type: 'error'
            }
        });
        localStorage.removeItem('auth')
    } else if (res.status === 400) {
        app.config.globalProperties.$notify({
            title: "Error",
            text: "Please contact support!",
            data: {
                type: 'error'
            }
        });
    } else if (res.status === 404) {
        app.config.globalProperties.$notify({
            title: "Error",
            text: "Requested resource not found!",
            data: {
                type: 'error'
            }
        });
    } else if (res.status === 403) {
        app.config.globalProperties.$notify({
            title: "Error",
            text: "Insufficient Permissions!",
            data: {
                type: 'error'
            }
        });
    } else {
        app.config.globalProperties.$notify({
            title: "Error",
            text: "Error: " + res.status,
            data: {
                type: 'error'
            }
        });
    }
}

app.config.globalProperties.getAPIHostname = function () {
    if(location.host.toLowerCase() === 'fl.lspdnet.com') return 'https://api.fl.lspdnet.com';
    else return 'https://api.lspdnet.com'
}

app.directive('click-outside', {
    mounted(el, binding) {
        el.clickOutsideEvent = function(event) {
            if (!(el === event.target || el.contains(event.target))) {
                binding.value(event, el);
            }
        };
        document.body.addEventListener('click', el.clickOutsideEvent);
    },
    unmounted(el) {
        document.body.removeEventListener('click', el.clickOutsideEvent);
    }
});

app.component('QuillEditor', QuillEditor)
app.use(i18n).use(i18n).use(router).mount('#app')
