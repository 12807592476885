<template>
  <router-link to="/patrol" class="pt-1 pb-2 text-gray-400 text-bold font-bold">
    <i class="fa-solid fa-arrow-left"></i> zurück
  </router-link>
  <AddPatrolModal ref="addPatrolModal"/>
  <AddVehicleModal ref="addVehicleModal"/>
  <AddStatusModal ref="addStatusModal"/>
  <SelectCopUserModal ref="selectCopUserModal" @done="getValues"/>
  <SelectStateModal ref="selectStateModal" @done="getValues"/>
  <SelectVehicleModal ref="selectVehicleModal" @done="getValues"/>
  <ConfirmModal ref="confirmModal"
                @yes="stopDienst"
                title="Bist du sicher?"
                message="Diese Aktion wird das gesamte Streifenblatt leeren!"/>
  <ConfirmModal ref="deleteState"
                @yes="deleteState"
                message="Diese Aktion löscht diesen Status-Code! (Keine Streife darf diesen Status verwenden)"/>
  <ConfirmModal ref="deleteVehicle"
                @yes="deleteVehicle"
                message="Diese Aktion löscht das Fahrzeug! (Keine Streife darf dieses Fahrzeug verwenden)"/>
  <ConfirmModal ref="deletePatrol"
                @yes="deletePatrol"
                message="Diese Aktion löscht diese Streife! (Diese Streife muss leer sein)"/>

  <div class="fixed text-gray-600 top-1 right-1 cursor-pointer font-mono" @click="getValues">
    <i class="fas fa-sync-alt"></i> {{ timeCount }}
  </div>

  <div class="flex justify-between w-full flex-wrap">
    <h4 class="pagetitle">Leitstelle</h4>
    <div class="flex gap-2 flex-wrap">
      <button v-show="this.$hasPerm('PATROL_EDIT_PATROL')" @click="$refs.addPatrolModal.show()" class="newButton">
        <i class="fa-solid fa-plus"></i> Streife Hinzufügen
      </button>
      <button v-show="this.$hasPerm('PATROL_EDIT_CAR')" @click="$refs.addVehicleModal.show()" class="newButton">
        <i class="fa-solid fa-plus"></i> Fahrzeug Hinzufügen
      </button>
      <button v-show="this.$hasPerm('PATROL_EDIT_CODE')" @click="$refs.addStatusModal.show()" class="newButton">
        <i class="fa-solid fa-plus"></i> Status Hinzufügen
      </button>
      <button v-show="this.$hasPerm('PATROL_USE')" @click="$refs.confirmModal.show()" class="newButton text-red-500">
        <i class="fa-solid fa-times text-red-500"></i> Streifenblatt Leeren
      </button>
    </div>
  </div>

  <div class="p-2 w-full h-fit relative" v-for="patrol in values.patrols" :key="patrol.name">
    <div class="newcard p-5 w-full">
      <h4 class="font-bold text-lg ">{{ patrol.name }}</h4>

      <div class="flex gap-5">
        <div class="cursor-pointer" @click="$refs.selectStateModal.show(patrol.id, staticValues.states)">
          <h3 class="font-bold mb-2 mt-0 text-1xl" v-if="patrol.patrolState"
              :style="'color: ' + patrol.patrolState.color">{{ patrol.patrolState.name }}</h3>
          <h3 class="font-bold mb-2 mt-0 text-1xl" v-else
              style="color: black">Unbekannt</h3>
        </div>
        <div class="cursor-pointer" @click="$refs.selectVehicleModal.show(patrol.id, staticValues.vehicles)">
          <h3 class="font-bold mb-2 mt-0 text-1xl" v-if="patrol.patrolVehicle">
            <i :class="['fas', 'fa-' + patrol.patrolVehicle.icon]"></i> {{ patrol.patrolVehicle.name }}</h3>
          <h3 class="font-bold mb-2 mt-0 text-1xl" v-else
              style="color: black"><i class="fas fa-car"></i> Unbekannt</h3>
        </div>
        <div class="cursor-pointer absolute top-4 right-4 hover:text-red-500" @click="$refs.deletePatrol.show(patrol)">
          <h3 class="font-bold mb-2 mt-0 text-1xl"><i class="fa-solid fa-trash"></i></h3>
        </div>

      </div>


      <div class="flex gap-2 justify-start">
        <div v-if="patrol.cop1">
          <div class="flex gap-2">
            <h3 class="font-bold ml-2 text-xl">{{ patrol.cop1.badgeNumber }}-{{ patrol.cop1.name }}</h3>
            <i class="fas fa-times text-red-500 hover:text-red-700 cursor-pointer"
               @click="removeFromPatrol(patrol.id, 1)"></i>
          </div>
        </div>
        <div v-else>
          <h3 class="w-full font-bold p-2 ml-2 text-xl text-gray-400 border-dashed border-2 border-gray-700 cursor-pointer"
              @click="$refs.selectCopUserModal.show(patrol.id, 1)">Nicht besetzt</h3>
        </div>
        <div v-if="patrol.cop2">
          <div class="flex gap-2">
            <h3 class="font-bold ml-2 text-xl">{{ patrol.cop2.badgeNumber }}-{{ patrol.cop2.name }}</h3>
            <i class="fas fa-times text-red-500 hover:text-red-700 cursor-pointer"
               @click="removeFromPatrol(patrol.id, 2)"></i>
          </div>
        </div>
        <div v-else>
          <h3 class="w-full font-bold p-2 ml-2 text-xl text-gray-400 border-dashed border-2 border-gray-700 cursor-pointer"
              @click="$refs.selectCopUserModal.show(patrol.id, 2)">Nicht besetzt</h3>
        </div>
        <div v-if="patrol.cop3">
          <div class="flex gap-2">
            <h3 class="font-bold ml-2 text-xl">{{ patrol.cop3.badgeNumber }}-{{ patrol.cop3.name }}</h3>
            <i class="fas fa-times text-red-500 hover:text-red-700 cursor-pointer"
               @click="removeFromPatrol(patrol.id, 3)"></i>
          </div>
        </div>
        <div v-else>
          <h3 class="w-full font-bold p-2 ml-2 text-xl text-gray-400 border-dashed border-2 border-gray-700 cursor-pointer"
              @click="$refs.selectCopUserModal.show(patrol.id, 3)">Nicht besetzt</h3>
        </div>
      </div>
    </div>
  </div>

  <div class="flex">

    <div class="w-1/2">
      <div class="flex justify-between w-full flex-wrap">
        <h4 class="pagetitle">Funkcodes</h4>
      </div>
      <div class="p-2 w-full h-fit relative">
        <table class="min-w-full dark">
          <thead class=" bg-gray-700">
          <tr>
            <th scope="col"
                class="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-gray-400">
              Name
            </th>
            <th scope="col"
                class="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-gray-400">
              Bedeutung
            </th>
            <th scope="col"
                v-show="this.$hasPerm('PATROL_EDIT_CODE')"
                class="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-gray-400">
              Löschen
            </th>
          </tr>
          </thead>
          <tbody>

          <tr
              class="border-b border-gray-700 transition"
              v-for="state in staticValues.states"
              :key="state.id"
              :style="'background-color: ' + state.color"
          >
            <td class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap font-bold"
                :style="'color: ' + textColorFromBackground(state.color)"
            >
              {{ state.name }}
            </td>
            <td class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap text-gray-400"
                :style="'color: ' + textColorFromBackground(state.color)"
            >
              {{ state.message }}
            </td>
            <td v-show="this.$hasPerm('PATROL_EDIT_CODE')" class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap">
              <button @click="this.$refs.deleteState.show(state)"
                      class="btn"
                      :style="'color: ' + textColorFromBackground(state.color)">Löschen
              </button>
            </td>
          </tr>


          </tbody>
        </table>
      </div>
    </div>
    <div class="w-1/2">
      <div class="flex justify-between w-full flex-wrap">
        <h4 class="pagetitle">Fahrzeuge</h4>
      </div>
      <div class="p-2 w-full h-fit relative">
        <table class="min-w-full dark">
          <thead class=" bg-gray-700">
          <tr>
            <th scope="col"
                class="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-gray-400">
              Name
            </th>
            <th scope="col"
                class="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-gray-400">
              Typ
            </th>
            <th scope="col"
                v-show="this.$hasPerm('PATROL_EDIT_CAR')"
                class="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-gray-400">
              Löschen
            </th>
          </tr>
          </thead>
          <tbody>

          <tr
              class="border-b border-gray-700 transition bg-gray-800"
              v-for="vehicle in staticValues.vehicles"
              :key="vehicle.id"
          >
            <td class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap font-bold"
            >
              {{ vehicle.name }}
            </td>
            <td class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap text-gray-400"
            >
              {{ vehicle.type }}
            </td>
            <td v-show="this.$hasPerm('PATROL_EDIT_CAR')" class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap">
              <button @click="this.$refs.deleteVehicle.show(vehicle)" class="btn">Löschen</button>
            </td>
          </tr>


          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import AddPatrolModal from "@/components/patrol/AddPatrolModal";
import AddVehicleModal from "@/components/patrol/AddVehicleModal";
import AddStatusModal from "@/components/patrol/AddStatusModal";
import SelectCopUserModal from "@/components/patrol/SelectCopUserModal";
import SelectStateModal from "@/components/patrol/SelectStateModal";
import SelectVehicleModal from "@/components/patrol/SelectVehicleModal";
import ConfirmModal from "@/components/modals/ConfirmModal";

export default {
  name: "PatrolControllView",
  components: {
    ConfirmModal,
    SelectVehicleModal, SelectStateModal, SelectCopUserModal, AddStatusModal, AddVehicleModal, AddPatrolModal
  },
  data() {
    return {
      timeCount: 5,
      department: JSON.parse(localStorage.user).department,
      user: JSON.parse(localStorage.user),
      staticValues: {
        states: [
          {
            name: "Code 0",
            color: '#00FF00',
            message: "Verfügbar"
          },
          {
            name: "Code 1",
            color: '#cdc359',
            message: "Anfahrt auf Einsatz"
          }
        ],
        patrols: [
          {
            name: "Vespucci"
          },
          {
            name: "Vinewood"
          }
        ]
      },
      values: {
        ownPatrol: {
          name: "Vespucci",
          state: {
            name: "Code 0",
            color: '#00FF00',
            message: "Verfügbar"
          }
        },
        patrols: [
          {
            name: "Loading...",
            state: {
              name: "Code 0",
              color: '#00FF00',
              message: "Verfügbar"
            }
          }
        ],
      }
    }
  },
  mounted() {
    this.getStatic();
    this.getValues();
    this.handleTimer();
  },
  methods: {
    getStatic() {
      fetch(this.getAPIHostname() + "/department/" + this.department.id + "/patrol/statics",
          {
            method: "GET",
            mode: "cors",
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Basic ' + localStorage.auth
            },
          }
      ).then(this.jsonParser).then(json => {
        this.staticValues = json;
      })
    },
    getValues() {
      console.log("fired")
      fetch(this.getAPIHostname() + "/department/" + this.department.id + "/patrol/values",
          {
            method: "GET",
            mode: "cors",
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Basic ' + localStorage.auth
            },
          }
      ).then(this.jsonParser).then(json => {
        this.values = json;
        this.timeCount = 5;
      })
    },
    stopDienst() {
      fetch(this.getAPIHostname() + "/department/" + this.department.id + "/patrol/patrol/clear",
          {
            method: "PATCH",
            mode: "cors",
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Basic ' + localStorage.auth
            }
          }
      ).then(this.jsonParser)
          .then(() => {
            this.getValues();
            this.$notify({
              title: "Success",
              text: " ",
              data: {
                type: 'success'
              }
            });
          })
    },
    removeFromPatrol(patrol, number) {
      fetch(this.getAPIHostname() + "/department/" + this.department.id + "/patrol/patrol/" + patrol + "/cop" + number,
          {
            method: "PATCH",
            mode: "cors",
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Basic ' + localStorage.auth
            },
            body: JSON.stringify(null)
          }
      ).then(this.jsonParser)
          .then(() => {
            this.getValues();
            this.$notify({
              title: "Success",
              text: " ",
              data: {
                type: 'success'
              }
            });
          })
    },
    handleTimer() {
      if (this.timeCount > 0) {
        this.timeCount = this.timeCount - 1;
      } else {
        this.getValues();
      }
      setTimeout(this.handleTimer, 1000);
    },
    textColorFromBackground(bgColor) {
      let color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
      let r = parseInt(color.substring(0, 2), 16); // hexToR
      let g = parseInt(color.substring(2, 4), 16); // hexToG
      let b = parseInt(color.substring(4, 6), 16); // hexToB
      let uicolors = [r / 255, g / 255, b / 255];
      let c = uicolors.map((col) => {
        if (col <= 0.03928) {
          return col / 12.92;
        }
        return Math.pow((col + 0.055) / 1.055, 2.4);
      });
      let L = (0.2126 * c[0]) + (0.7152 * c[1]) + (0.0722 * c[2]);
      return (L > 0.179) ? 'black' : 'white';
    },
    deleteState(state) {
      fetch(this.getAPIHostname() + "/department/" + this.department.id + "/patrol/state/" + state.id,
          {
            method: "DELETE",
            mode: "cors",
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Basic ' + localStorage.auth
            }
          }
      ).then(this.jsonParser)
          .then(() => {
            this.getStatic();
            this.$notify({
              title: "Success",
              text: " ",
              data: {
                type: 'success'
              }
            });
          })
    },
    deleteVehicle(vehicle) {
      fetch(this.getAPIHostname() + "/department/" + this.department.id + "/patrol/vehicle/" + vehicle.id,
          {
            method: "DELETE",
            mode: "cors",
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Basic ' + localStorage.auth
            }
          }
      ).then(this.jsonParser)
          .then(() => {
            this.getStatic();
            this.$notify({
              title: "Success",
              text: " ",
              data: {
                type: 'success'
              }
            });
          })
    },
    deletePatrol(patrol) {
      fetch(this.getAPIHostname() + "/department/" + this.department.id + "/patrol/patrol/" + patrol.id,
          {
            method: "DELETE",
            mode: "cors",
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Basic ' + localStorage.auth
            }
          }
      ).then(this.jsonParser)
          .then(() => {
            this.getStatic();
            this.$notify({
              title: "Success",
              text: " ",
              data: {
                type: 'success'
              }
            });
          })
    }
  }
}
</script>

<style scoped>

</style>
