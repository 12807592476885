<template>
  <EditCopUserModal ref="edit" @sent="loadData"/>
  <EditFileEntryModal @sent="loadFiles" ref="editModal" />
  <button class="staticButton" @click="$refs.edit.show(copUser)"><i class="fa-solid fa-pen"></i></button>
  <div class="smallcards">
    <InfoCard
        title="Dienstnummer"
        :content="copUser.badgeNumber"
        icon="fa-certificate"
        color="#DB5461"></InfoCard>
    <InfoCard
        title="Name"
        :content="copUser.name"
        icon="fa-tag"
        color="#371E30"></InfoCard>
    <InfoCard
        title="Rang"
        :content="copUser.permissionName"
        icon="fa-check-to-slot"
        color="#246EB9"></InfoCard>
    <InfoCard
        title="Akten-Einträge"
        :content="fileEntries.length"
        icon="fa-file"
        color="blue"></InfoCard>
  </div>


  <div class="card">
    <div class="title">Erstellte Einträge</div>
    <hr>
    <div class="card-content">


      <table class="min-w-full dark">
        <thead class=" bg-gray-700">
        <tr>
          <th scope="col"
              class="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-gray-400">
            Grund
          </th>
          <th scope="col"
              class="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-gray-400">
            Name
          </th>
          <th scope="col"
              class="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-gray-400">
            Datum
          </th>
          <th scope="col"
              class="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-gray-400">
            Gefängnis
          </th>
          <th scope="col"
              class="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-gray-400">
            Geldstrafe
          </th>
        </tr>
        </thead>
        <tbody>

        <tr
            @click="$refs.editModal.show(entry, entry.fileID)"
            :class="['border-b bg-gray-800 border-gray-700', entry.wanted ? 'blink' : '']"
            v-for="entry in fileEntries" :key="entry.id"
        >
          <td class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap">
            <span class="text-white">
            {{ entry.reason }}
            </span>
          </td>
          <td class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap text-white">
            <span class="text-white">
            {{ entry.name }}
            </span>
          </td>
          <td class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap text-white">
            <span class="text-white">
            {{ toDate(entry.timestamp) }}
            </span>
          </td>
          <td class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap text-white">
            <span class="text-white">
            {{ entry.prisonTime }}
            </span>
          </td>
          <td class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap text-white">
            <span class="text-white">
            {{ entry.billAmount }}
            </span>
          </td>
        </tr>

        </tbody>
      </table>


    </div>
  </div>

</template>

<script>
import InfoCard from "@/components/InfoCard";
import EditCopUserModal from "@/components/EditCopUserModal";
import EditFileEntryModal from "@/components/EditFileEntryModal";

export default {
  name: "CopUserView",
  components: {EditFileEntryModal, EditCopUserModal, InfoCard},
  data() {
    return {
      department: JSON.parse(localStorage.user).department,
      copUser: {
        badgeNumber: '???',
        name: '???',
        permission: '???',
      },
      fileEntries: []
    }
  },
  methods: {
    loadData: function () {
      fetch(this.getAPIHostname() + "/department/" + this.department.id + "/cop/" + this.$route.params.id,
          {
            method: "GET",
            mode: "cors",
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Basic ' + localStorage.auth
            },
          }
      )
          .then(this.jsonParser)
          .then(json => {
            this.copUser = json;
            this.copUser.permissionName = this.copUser.permissionGroup.name;
          })
    },
    loadFiles() {
      fetch(this.getAPIHostname() + "/department/" + this.department.id + "/cop/" + this.$route.params.id + '/entries',
          {
            method: "GET",
            mode: "cors",
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Basic ' + localStorage.auth
            },
          }
      )
          .then(this.jsonParser)
          .then(json => this.fileEntries = json)
    },
    toDate(millies){
      return new Date(millies).toLocaleString()
    },
  },
  mounted() {
    this.loadData();
    this.loadFiles();
  }
}
</script>

<style scoped>
.smallcards {
  display: flex;
  gap: 10px;
  margin-bottom: 3rem;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  overflow-x: hidden;
}

.smallcards * {
  width: 30%;
}
</style>
