<template>
  <div v-show="showModal" class="fixed z-20 inset-0 overflow-y-auto " aria-labelledby="modal-title" role="dialog"
       aria-modal="true">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 ">
      <div class="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
      <div id="popup"
           class="inline-block align-bottom dark bg-gray-800 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
        <div class=" px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div class="flex items-start">
            <div
                class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-gray-900 sm:mx-0 sm:h-10 sm:w-10">
              <i class="fa fa-plus-circle"></i>
            </div>
            <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
              <h3 class="text-lg leading-6 font-medium " id="modal-title">Eintrag bearbeiten</h3>
              <div class="mt-2">
                <div class="flex flex-col mt-8 space-y-3 sm:space-y-0 sm:flex-row sm:justify-start sm:-mx-2">

                  <form class="w-full pr-6">

                    <div class="flex gap-3 flex-wrap">
                      <div class="w-1/3">
                        <label class="text-gray-200" for="badge">Kurzbeschreibung</label>
                        <input disabled id="badge"
                               v-model="object.reason"
                               type="text"
                               class="block w-full px-4 py-2 mt-2 text-gray-700 border border-gray-200 rounded-md bg-gray-800 text-gray-300 border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 focus:border-blue-300 focus:outline-none focus:ring">
                      </div>

                      <div class="w-2/3">
                        <label class="text-gray-200" for="prison">Erläuterung des Vorfalls</label>
                        <textarea disabled id="prison"
                                  v-model="object.information"
                                  type="number"
                                  class="block w-full px-4 py-2 mt-2 text-gray-700 border border-gray-200 rounded-md bg-gray-800 text-gray-300 border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 focus:border-blue-300 focus:outline-none focus:ring">
                          </textarea>
                      </div>


                      <div class="w-full">
                        <label class="text-gray-200" for="badge">Wanted</label> <br>
                        <label class="switch">
                          <input id="updateSendMessage" type="checkbox" v-model="object.wanted">
                          <span class="slider"></span>
                        </label>
                      </div>
                    </div>

                    <div class="mt-4">

                      <div class="mt-2 bg-gray-600 rounded" v-for="lawbook in lawbooks" :key="lawbook.id">
                        <h3 class="p-5">{{ lawbook.name }}</h3>
                        <hr>

                        <div
                            v-for="law in lawbook.laws"
                            :key="law.id"
                            class="flex flex-row justify-between mt-2 p-2">
                          <div>{{ law.name }}</div>
                          <input
                              v-model="law.amount"
                              min="0"
                              class="block p-1 w-16 text-gray-700 border border-gray-200 rounded-md bg-gray-700 text-gray-300 border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 focus:border-blue-300 focus:outline-none focus:ring"
                              type="number">
                        </div>

                      </div>

                    </div>


                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-show="prepared">
          Gefängnis: {{ object.prisonTime }}
          Geldstrafe: {{ object.billAmount }}
        </div>

        <div class="px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <button @click="close" type="button"
                  class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2  text-base font-medium hover:text-gray-900  hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
            Abort
          </button>
          <button @click="clicked" type="button"
                  class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm">
            Submit
          </button>
          <button @click="remove()" type="button"
                  v-show="this.$hasPerm('FILE_ENTRY_DELETE')"
                  class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm">
            Delete
          </button>
        </div>

      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: "EditFileEntryModal",
  data() {
    return {
      lawbooks: [],
      showModal: false,
      department: JSON.parse(localStorage.user).department,
      prepared: false,
      fileID: -1,
      object: {
        id: "",
        reason: "",
        information: "",
        prisonTime: 0,
        prisonServed: 0,
        billAmount: 0,
        wanted: 0
      },
      lawBook: -1
    }
  },
  methods: {
    show(entry, fileID) {
      this.object = entry;
      this.fileID = fileID;
      this.showModal = true;
    },
    close() {
      this.showModal = false;
    },
    clicked() {

      fetch(this.getAPIHostname() + "/department/" + this.department.id + "/file/" + this.fileID + "/entries/" + this.object.id + "/setwanted",
          {
            method: "PATCH",
            mode: "cors",
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Basic ' + localStorage.auth
            },
            body: this.object.wanted
          }
      ).then(this.jsonParser)
      .then(() => {
        this.$notify({
          title: "Success",
          text: " ",
          data: {
            type: 'success'
          }
        });
      })

    },
    remove() {

      fetch(this.getAPIHostname() + "/department/" + this.department.id + "/file/" + this.fileID + "/entries/" + this.object.id,
          {
            method: "DELETE",
            mode: "cors",
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Basic ' + localStorage.auth
            },
          }
      ).then(this.jsonParser)
      .then(() => {
        this.close()
        this.$emit("sent");
        this.$notify({
          title: "Success",
          text: " ",
          data: {
            type: 'success'
          }
        });
      })

    },
  },
  mounted() {

  }
}
</script>

<style scoped>

</style>
