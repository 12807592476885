<template>
  <div :class="[collapsed ? 'collapsed' : '']">
    <div class="sidebar flex dark flex-col h-full border-r bg-gray-800 border-gray-600">
      <div class="flex justify-between justify-center items-center w-full">

      </div>

      <div id="scroll" class="flex flex-col justify-between flex-1 mt-6 px-4">
        <nav class="mb-2">
          <div v-for="page in this.paths" :key="page.path">
            <hr v-show="page.splitBefore && page.display && doShow(page)" class="my-6  dark:border-gray-600"/>

            <router-link
                :class="[page.path == $route.path ?
              'flex items-center px-4 py-2 nav-item rounded-md dark:bg-gray-700 dark:text-gray-200' :
              'flex items-center px-4 py-2 nav-item  transition-colors duration-200 transform rounded-md dark:text-gray-400  dark:hover:bg-gray-700 dark:hover:text-gray-200 '
              ]"
                :to="page.path"
                v-show="page.display && doShow(page)"
            >
              <i :class="['w-5 h-5', page.icon]"></i>
              <span v-show="!collapsed" class="mx-4 font-medium">{{ $t(page.displayName) }}</span>
            </router-link>

          </div>
        </nav>
        <div v-show="!collapsed">
          <p> © 2022-2023 </p>
          <a href="https://dash.primeapi.de/imprint">Imprint</a>
        </div>

      </div>
    </div>
  </div>
</template>

<script>

import routes from "@/router/routes.js";

export default {
  name: "SideBar",
  data() {
    return {
      paths: routes,
      collapsed: false,
    }
  },
  props: {
  },
  methods: {
    doShow(page) {
     if (page.path === '/logout') return true;
      if (localStorage.user == null) return false;
      else {
        let user = JSON.parse(localStorage.user);
        if (user.badgeNumber == null) return false;
        else {
          if(page.permission == null) return true;
          let permissions = user.permissionGroup.permissionType;
          return permissions.includes(page.permission);
        }
      }
    },
    toggleCollapsed(){
      this.collapsed = !this.collapsed;
      localStorage.collapsed = this.collapsed;
      this.$emit("collapse", this.collapsed);
    }
  },
  mounted(){

    if(localStorage.collapsed == null){
      this.collapsed = false;
      this.$emit("collapse", false);
    }else {
      this.collapsed = localStorage.collapsed == "true";
      this.$emit("collapse", this.collapsed);
    }
  }
}
</script>

<style scoped>
#scroll {
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

#scroll::-webkit-scrollbar {
  display: none;
}

.text-3xl {
  margin-left: 19%;
}

.sidebar {
  transition: 500ms;
  width: 16rem;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
}

.collapsed .sidebar {
  width: 5rem;
}

.nav-item{
  margin-top: 1.25rem;
}
.collapsed .nav-item{
  margin-top: 0.5rem;
  text-align: center;
}

.collapsed i{
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.switchButton{
  transition: 200ms;
  padding: 1rem;
  top: 0;
  height: 3rem;
  font-size: 1.25rem;
}

.switchButton:focus{
  outline: none;
}

.collapsed .switchButton{
  border-bottom-left-radius: 0;
}


hr {

}

router-link {
}


</style>
