<template>
  <div class="flex justify-between f-full out">
    <router-link to="/patrolcontroll" v-show="this.$hasPerm('PATROL_CONTROLLER')" class="pt-1 pb-2 text-gray-400 text-bold font-bold">
      <i class="fa-solid fa-cogs"></i> zur Leitstelle
    </router-link>
    <div @click="stopDienst"
         v-show="values.ownPatrol"
         class="pt-1 pb-2 text-red-500 hover:text-red-700 text-bold font-bold cursor-pointer">
      <i class="fa-solid fa-times"></i> Dienst beenden
    </div>
  </div>

  <SelectCopUserModal ref="selectCopUserModal" @done="getValues"/>
  <SelectStateModal ref="selectStateModal" @done="getValues"/>
  <SelectVehicleModal ref="selectVehicleModal" @done="getValues"/>

  <div class="fixed text-gray-600 top-1 right-1 cursor-pointer font-mono" @click="getValues">
    <i class="fas fa-sync-alt"></i> {{ timeCount }}
  </div>

  <div class="flex justify-between w-full flex-wrap" v-show="values.ownPatrol">
    <h4 class="pagetitle">Deine Streife</h4>
    <div class="flex gap-2">
      <div>
        <button class="newButton" id="statusButton">
          <i class="fa-solid fa-signal"></i> Status setzen
        </button>
        <div class="absolute">
          <div v-show="toggles.status"
               class=""
               v-for="state in staticValues.states"
               :key="state.name"
               :style="'color: ' + state.color">
            <button @click="setState(state)" class="newButton w-full">
              {{ state.name }}
            </button>
          </div>
        </div>
      </div>
      <div>
        <button id="vehicleButton" class="newButton">
          <i class="fa-solid fa-car-side"></i> Fahrzeug auswählen
        </button>
        <div class="absolute">
          <div v-show="toggles.patrol" class="" v-for="vehicle in staticValues.vehicles" :key="vehicle.id">
            <button @click="setVehicle(vehicle)" class="newButton w-full">
              <i :class="['fas', 'fa-' + vehicle.icon ]"></i> {{ vehicle.name }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="flex flex-warp mb-10" v-show="values.ownPatrol">

    <div class="flex flex-col w-1/3 flex-warp">

      <div class="p-2 w-full h-fit">
        <div class="newcard p-5 w-full">
          <h4 class="font-bold mb-2 text-lg">Name</h4>
          <div v-if="values.ownPatrol">
            <h3 class="font-bold mb-2 mt-0 text-1xl" v-if="values.ownPatrol.patrolState"
                :style="'color: ' + values.ownPatrol.patrolState.color">{{ values.ownPatrol.patrolState.name }}</h3>
            <h3 class="font-bold mb-2 mt-0 text-1xl" v-else
                style="color: black">Unbekannt</h3>

            <h3 class="font-bold ml-2 text-3xl">{{ values.ownPatrol.name }}</h3>
          </div>
          <div v-else>
            <h3 class="font-bold ml-2 text-3xl text-red">Außer Dienst</h3>
          </div>
        </div>
      </div>

      <div class="p-2 w-full h-fit">
        <div class="newcard p-5 w-full">
          <h4 class="font-bold mb-2 text-lg">Fahrzeug</h4>

          <div v-if="values.ownPatrol">
            <h3 class="font-bold mb-2 mt-0 text-1xl" v-if="values.ownPatrol.patrolVehicle">
              <i :class="['fas', 'fa-' + values.ownPatrol.patrolVehicle.icon]"></i> {{
                values.ownPatrol.patrolVehicle.name
                                                                                    }}</h3>
            <h3 class="font-bold mb-2 mt-0 text-1xl" v-else
                style="color: black"><i class="fas fa-car"></i> Unbekannt</h3>

          </div>
          <div v-else>
            <h3 class="font-bold ml-2 text-3xl text-red">Außer Dienst</h3>
          </div>
        </div>
      </div>

    </div>

    <div v-if="values.ownPatrol" class="flex flex-col w-2/3 flex-warp">
      <div class="p-2 w-full h-fit">
        <div class="newcard p-5 w-full">
          <h4 class="font-bold mb-2 text-lg">Fahrer</h4>
          <div v-if="values.ownPatrol.cop1">
            <div class="flex gap-2">
              <h3 class="font-bold ml-2 text-xl">{{ values.ownPatrol.cop1.badgeNumber }}-{{
                  values.ownPatrol.cop1.name
                                                 }}</h3>
              <i class="fas fa-times text-red-500 hover:text-red-700 cursor-pointer"
                 @click="removeFromPatrol(values.ownPatrol.id, 1)"></i>
            </div>
          </div>
          <div v-else>
            <h3 class="w-full font-bold p-2 ml-2 text-xl text-gray-400 border-dashed border-2 border-gray-700 cursor-pointer"
                @click="$refs.selectCopUserModal.show(values.ownPatrol.id, 1)">Nicht besetzt</h3>
          </div>
        </div>
      </div>

      <div class="p-2 w-full h-fit">
        <div class="newcard p-5 w-full">
          <h4 class="font-bold mb-2 text-lg">2. Besatzung</h4>
          <div v-if="values.ownPatrol.cop2">
            <div class="flex gap-2">
              <h3 class="font-bold ml-2 text-xl">{{ values.ownPatrol.cop2.badgeNumber }}-{{
                  values.ownPatrol.cop2.name
                                                 }}</h3>
              <i class="fas fa-times text-red-500 hover:text-red-700 cursor-pointer"
                 @click="removeFromPatrol(values.ownPatrol.id, 2)"></i>
            </div>
          </div>
          <div v-else>
            <h3 class="w-full font-bold p-2 ml-2 text-xl text-gray-400 border-dashed border-2 border-gray-700 cursor-pointer"
                @click="$refs.selectCopUserModal.show(values.ownPatrol.id, 2)">Nicht besetzt</h3>
          </div>
        </div>
      </div>

      <div class="p-2 w-full h-fit">
        <div class="newcard p-5 w-full">
          <h4 class="font-bold mb-2 text-lg">3. Besatzung</h4>
          <div v-if="values.ownPatrol.cop3">
            <div class="flex gap-2">
              <h3 class="font-bold ml-2 text-xl">{{ values.ownPatrol.cop3.badgeNumber }}-{{
                  values.ownPatrol.cop3.name
                                                 }}</h3>
              <i class="fas fa-times text-red-500 hover:text-red-700 cursor-pointer"
                 @click="removeFromPatrol(values.ownPatrol.id, 3)"></i>
            </div>
          </div>
          <div v-else>
            <h3 class="w-full font-bold p-2 ml-2 text-xl text-gray-400 border-dashed border-2 border-gray-700 cursor-pointer"
                @click="$refs.selectCopUserModal.show(values.ownPatrol.id, 3)">Nicht besetzt</h3>
          </div>
        </div>
      </div>

    </div>

  </div>

  <div class="flex">
    <div class="w-2/3">
      <div class="flex justify-between w-full flex-wrap">
        <h4 class="pagetitle">Alle Streifen</h4>
      </div>
      <div class="p-2 w-full h-fit relative" v-for="patrol in values.patrols" :key="patrol.name">
        <div class="newcard p-5 w-full">
          <h4 class="font-bold text-lg ">{{ patrol.name }}</h4>

          <div class="flex gap-5">
            <div class="">
              <h3 class="font-bold mb-2 mt-0 text-1xl" v-if="patrol.patrolState"
                  :style="'color: ' + patrol.patrolState.color">{{ patrol.patrolState.name }}</h3>
              <h3 class="font-bold mb-2 mt-0 text-1xl" v-else
                  style="color: black">Unbekannt</h3>
            </div>
            <div class="">
              <h3 class="font-bold mb-2 mt-0 text-1xl" v-if="patrol.patrolVehicle">
                <i :class="['fas', 'fa-' + patrol.patrolVehicle.icon]"></i> {{ patrol.patrolVehicle.name }}</h3>
              <h3 class="font-bold mb-2 mt-0 text-1xl" v-else
                  style="color: black"><i class="fas fa-car"></i> Unbekannt</h3>
            </div>
          </div>


          <div class="flex gap-2 justify-start">
            <div v-if="patrol.cop1">
              <div class="flex gap-2">
                <h3 class="font-bold ml-2 text-xl">{{ patrol.cop1.badgeNumber }}-{{ patrol.cop1.name }}</h3>
              </div>
            </div>
            <div v-else>
              <h3 class="w-full font-bold p-2 ml-2 text-xl text-gray-400 border-dashed border-2 border-gray-700 cursor-pointer"
                  @click="setPatrolCop(patrol.id, 1, user)">Jetzt eintragen</h3>
            </div>
            <div v-if="patrol.cop2">
              <div class="flex gap-2">
                <h3 class="font-bold ml-2 text-xl">{{ patrol.cop2.badgeNumber }}-{{ patrol.cop2.name }}</h3>
              </div>
            </div>
            <div v-else>
              <h3 class="w-full font-bold p-2 ml-2 text-xl text-gray-400 border-dashed border-2 border-gray-700 cursor-pointer"
                  @click="setPatrolCop(patrol.id, 2, user)">Jetzt eintragen</h3>
            </div>
            <div v-if="patrol.cop3">
              <div class="flex gap-2">
                <h3 class="font-bold ml-2 text-xl">{{ patrol.cop3.badgeNumber }}-{{ patrol.cop3.name }}</h3>
              </div>
            </div>
            <div v-else>
              <h3 class="w-full font-bold p-2 ml-2 text-xl text-gray-400 border-dashed border-2 border-gray-700 cursor-pointer"
                  @click="setPatrolCop(patrol.id, 3, user)">Jetzt eintragen</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="w-1/3">

      <div class="flex justify-between w-full flex-wrap">
        <h4 class="pagetitle">Funkcodes</h4>
      </div>
      <div class="p-2 w-full h-fit relative">
        <table class="min-w-full dark">
          <thead class=" bg-gray-700">
          <tr>
            <th scope="col"
                class="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-gray-400">
              Name
            </th>
            <th scope="col"
                class="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-gray-400">
              Bedeutung
            </th>
          </tr>
          </thead>
          <tbody>

          <tr
              class="border-b border-gray-700 transition"
              v-for="state in staticValues.states"
              :key="state.id"
              :style="'background-color: ' + state.color"
          >
            <td class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap font-bold"
                :style="'color: ' + textColorFromBackground(state.color)"
            >
              {{ state.name }}
            </td>
            <td class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap text-gray-400"
                :style="'color: ' + textColorFromBackground(state.color)"
            >
              {{ state.message }}
            </td>
          </tr>


          </tbody>
        </table>
      </div>
    </div>

  </div>


</template>

<script>
import SelectVehicleModal from "@/components/patrol/SelectVehicleModal";
import SelectStateModal from "@/components/patrol/SelectStateModal";
import SelectCopUserModal from "@/components/patrol/SelectCopUserModal";

export default {
  name: "PatrolView",
  components: {SelectVehicleModal, SelectStateModal, SelectCopUserModal},
  data() {
    return {
      timeCount: 5,
      department: JSON.parse(localStorage.user).department,
      user: JSON.parse(localStorage.user),
      toggles: {
        status: false,
        patrol: false
      },
      staticValues: {
        states: [
          {
            name: "Code 0",
            color: '#00FF00',
            message: "Verfügbar"
          },
          {
            name: "Code 1",
            color: '#cdc359',
            message: "Anfahrt auf Einsatz"
          }
        ],
        patrols: [
          {
            name: "Vespucci"
          },
          {
            name: "Vinewood"
          }
        ]
      },
      values: {
        ownPatrol: {
          name: "Vespuccii",
          state: {
            name: "Code 0",
            color: '#00FF00',
            message: "Verfügbar"
          },
          vehicle: {
            name: "Bufallo"
          }
        },
        patrols: [
          {
            name: "Vespucci",
            state: {
              name: "Code 0",
              color: '#00FF00',
              message: "Verfügbar"
            }
          },
          {
            name: "Vinewood",
            state: {
              name: "Code 0",
              color: '#00FF00',
              message: "Verfügbar"
            }
          }
        ],
      }
    }
  },
  mounted() {
    this.getStatic();
    this.getValues();
    this.handleTimer();

    window.addEventListener('click', ev => {
      this.toggles.status = ev.target.id === "statusButton";
      this.toggles.patrol = ev.target.id === "vehicleButton";
    })
  },
  methods: {
    getStatic() {
      fetch(this.getAPIHostname() + "/department/" + this.department.id + "/patrol/statics",
          {
            method: "GET",
            mode: "cors",
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Basic ' + localStorage.auth
            },
          }
      ).then(this.jsonParser).then(json => {
        this.staticValues = json;
      })
    },
    getValues() {
      fetch(this.getAPIHostname() + "/department/" + this.department.id + "/patrol/values",
          {
            method: "GET",
            mode: "cors",
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Basic ' + localStorage.auth
            },
          }
      ).then(this.jsonParser).then(json => {
        this.values = json;
        this.timeCount = 5;
      })
    },
    removeFromPatrol(patrol, number) {
      this.setPatrolCop(patrol, number, null);
    },
    setPatrolCop(patrol, number, cop) {
      fetch(this.getAPIHostname() + "/department/" + this.department.id + "/patrol/patrol/" + patrol + "/cop" + number,
          {
            method: "PATCH",
            mode: "cors",
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Basic ' + localStorage.auth
            },
            body: JSON.stringify(cop)
          }
      ).then(this.jsonParser)
          .then(() => {
            this.getValues();
            this.$notify({
              title: "Success",
              text: " ",
              data: {
                type: 'success'
              }
            });
          })
    },
    handleTimer() {
      if (this.timeCount > 0) {
        this.timeCount = this.timeCount - 1;
      } else {
        this.getValues();
      }
      setTimeout(this.handleTimer, 1000);
    },
    setState(state) {
      if (!this.values.ownPatrol) {
        this.$notify({
          title: "Error",
          text: "Wähle zunächst eine Streife aus!",
          data: {
            type: 'error'
          }
        });
        return;
      }
      fetch(this.getAPIHostname() + "/department/" + this.department.id + "/patrol/patrol/" + this.values.ownPatrol.id + "/state",
          {
            method: "PATCH",
            mode: "cors",
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Basic ' + localStorage.auth
            },
            body: JSON.stringify(state)
          }
      ).then(this.jsonParser)
          .then(() => {
            this.getValues();
            this.$notify({
              title: "Success",
              text: " ",
              data: {
                type: 'success'
              }
            });
          })
    },
    stopDienst() {
      fetch(this.getAPIHostname() + "/department/" + this.department.id + "/patrol/clear",
          {
            method: "PATCH",
            mode: "cors",
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Basic ' + localStorage.auth
            }
          }
      ).then(this.jsonParser)
          .then(() => {
            this.getValues();
            this.$notify({
              title: "Success",
              text: " ",
              data: {
                type: 'success'
              }
            });
          })
    },
    setVehicle(vehicle) {
      if (!this.values.ownPatrol) {
        this.$notify({
          title: "Error",
          text: "Wähle zunächst eine Streife aus!",
          data: {
            type: 'error'
          }
        });
        return;
      }
      fetch(this.getAPIHostname() + "/department/" + this.department.id + "/patrol/patrol/" + this.values.ownPatrol.id + "/vehicle",
          {
            method: "PATCH",
            mode: "cors",
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Basic ' + localStorage.auth
            },
            body: JSON.stringify(vehicle)
          }
      ).then(this.jsonParser)
          .then(() => {
            this.getValues();
            this.$notify({
              title: "Success",
              text: " ",
              data: {
                type: 'success'
              }
            });
          })
    },
    textColorFromBackground(bgColor) {
      let color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
      let r = parseInt(color.substring(0, 2), 16); // hexToR
      let g = parseInt(color.substring(2, 4), 16); // hexToG
      let b = parseInt(color.substring(4, 6), 16); // hexToB
      let uicolors = [r / 255, g / 255, b / 255];
      let c = uicolors.map((col) => {
        if (col <= 0.03928) {
          return col / 12.92;
        }
        return Math.pow((col + 0.055) / 1.055, 2.4);
      });
      let L = (0.2126 * c[0]) + (0.7152 * c[1]) + (0.0722 * c[2]);
      return (L > 0.179) ? 'black' : 'white';
    }
  }
}
</script>

<style scoped>

</style>
