<template>
  <router-link id="link" class="flex flex-nowrap gap-2 bg-gray-900 h-auto w-full" :to="'/file/' + file.id">
    <img id="image" class="h-16 w-16 rounded-lg p-1" :src="file.imgLink">
    <span id="name" class="text-white self-center">{{file.name}}</span>
  </router-link>
  <hr>
</template>

<script>
export default {
  name: "FileFoundComponent",
  props: {
    file: Object,
  }
}
</script>

<style scoped>

#link:hover #name{
  color: var(--red);
}

</style>
