<template>
  <AddFileModal ref="modal"></AddFileModal>

  <div id="link" @click="$refs.modal.show()" class="flex flex-nowrap gap-2 bg-gray-900 h-auto w-full rounded-b-lg">
    <div class="p-1 h-16 w-16"><i id="icon" class="fa-solid fa-plus rounded-lg border-dashed border-2 h-full w-full flex justify-center items-center font-9xl"></i></div>
    <span id="name" class="text-white self-center">Akte hinzufügen...</span>
  </div>
</template>

<script>
import AddFileModal from "@/components/AddFileModal";
export default {
  name: "FileAddComponent",
  components: {
    AddFileModal,
  }
}
</script>

<style scoped>
#link:hover #name{
  color: var(--red);
}

#link:hover{
  cursor: pointer;
}
</style>
