<template>
  <DiscordCard />

  <div class="card">
    <div class="title">
      Einstellungen
    </div>
    <hr>
    <div class="card-content">
        <form>
                      <div>
                        <label class="text-gray-200" for="loginUsername">Neues Password</label>
                        <input id="loginUsername"
                               v-model="newPassword"
                               type="password"
                               class="block w-full px-4 py-2 mt-2 mb-2 text-gray-700 border border-gray-200 rounded-md bg-gray-800 text-gray-300 border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 focus:border-blue-300 focus:outline-none focus:ring">
                      </div>                      <div>
                        <label class="text-gray-200" for="loginUsername">Passwort wiederholen</label>
                        <input id="loginUsername2"
                               v-model="newPassword2"
                               type="password"
                               class="block w-full px-4 py-2 mt-2 text-gray-700 border border-gray-200 rounded-md bg-gray-800 text-gray-300 border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 focus:border-blue-300 focus:outline-none focus:ring">
                      </div>
                                <div class="px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <button @click="submit" type="button"
                  class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm">
            Submit
          </button>
          </div>
        </form>
    </div>
  </div>


</template>

<script>
import DiscordCard from "@/components/DiscordCard";
export default {
  name: "SettingsView",
  components: {DiscordCard},
  data() {
    return{
        newPassword: "",
        newPassword2: "",
        department: JSON.parse(localStorage.user).department,
        user: JSON.parse(localStorage.user),
    }
  },
  mounted() {
    console.log("here")
  },
  methods: {
    submit() {
      if(this.newPassword != this.newPassword2) {
        this.$notify({
          title: "The password do not match",
          text: " ",
          data: {
            type: 'error'
          }
        });
        return;
      }
      fetch(this.getAPIHostname() + "/department/" + this.department.id + "/cop/" + this.user.badgeNumber + "/changePW",
          {
            method: "PATCH",
            mode: "cors",
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Basic ' + localStorage.auth
            },
            body: this.newPassword
          }
      ).then(response => {
        if(response.status == 200){
            window.location.href = "/logout"
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
