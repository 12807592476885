<template>


  <ConfirmModal ref="deleteFile"
                @yes="nowDelete"
                message="Diese Aktion löscht die Akte. (Unwiderruflich)"/>

  <div v-show="showModal" class="fixed z-20 inset-0 overflow-y-auto " aria-labelledby="modal-title" role="dialog"
       aria-modal="true">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 ">
      <div class="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
      <div id="popup"
           class="inline-block align-bottom dark bg-gray-800 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
        <div class=" px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div class="sm:flex sm:items-start">
            <div
                class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-gray-900 sm:mx-0 sm:h-10 sm:w-10">
              <i class="fa fa-plus-circle"></i>
            </div>
            <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <h3 class="text-lg leading-6 font-medium " id="modal-title">
                Edit File
              </h3>
              <div class="mt-2">
                <div class="flex flex-col mt-8 space-y-3 sm:space-y-0 sm:flex-row sm:justify-start sm:-mx-2">

                  <form>
                    <div class="flex flex-row gap-3">
                      <div class="flex flex-col gap-5">

                        <div>
                          <label class="text-gray-200" for="name">Name</label>
                          <input id="name"
                                 v-model="fileObject.name"
                                 type="text"
                                 class="block w-full px-4 py-2 mt-2 text-gray-700 border border-gray-200 rounded-md bg-gray-800 text-gray-300 border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 focus:border-blue-300 focus:outline-none focus:ring">
                        </div>
                        <div>
                          <label class="text-gray-200" for="birth">Birthday</label>
                          <input id="birth"
                                 v-model="fileObject.birthdate"
                                 type="text"
                                 class="block w-full px-4 py-2 mt-2 text-gray-700 border border-gray-200 rounded-md bg-gray-800 text-gray-300 border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 focus:border-blue-300 focus:outline-none focus:ring">
                        </div>
                        <div>
                          <label class="text-gray-200" for="height">Height</label>
                          <input id="height"
                                 v-model="fileObject.height"
                                 type="number"
                                 class="block w-full px-4 py-2 mt-2 text-gray-700 border border-gray-200 rounded-md bg-gray-800 text-gray-300 border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 focus:border-blue-300 focus:outline-none focus:ring">
                        </div>
                        <div>
                          <label class="text-gray-200" for="tel">Phone</label>
                          <input id="tel"
                                 v-model="fileObject.telnumber"
                                 type="text"
                                 class="block w-full px-4 py-2 mt-2 text-gray-700 border border-gray-200 rounded-md bg-gray-800 text-gray-300 border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 focus:border-blue-300 focus:outline-none focus:ring">
                        </div>
                        <div>
                          <label class="text-gray-200" for="prison">Notiz</label>
                          <textarea id="prison"
                                    v-model="fileObject.note"
                                    type="text"
                                    class="block w-full px-4 py-2 mt-2 text-gray-700 border border-gray-200 rounded-md bg-gray-800 text-gray-300 border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 focus:border-blue-300 focus:outline-none focus:ring">
                          </textarea>
                        </div>
                      </div>
                      <div class="flex flex-col">


                        <img class="rounded" :src="imgURL" height="350" width="350">

                        <label class="text-gray-200 mt-2" for="url">Img-URL</label>
                        <input
                            @blur="updateImage"
                            id="url"
                               v-model="fileObject.imgLink"
                               type="text"
                               class="block w-full px-4 py-2 mt-2 text-gray-700 border border-gray-200 rounded-md bg-gray-800 text-gray-300 border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 focus:border-blue-300 focus:outline-none focus:ring">
                      </div>
                    </div>
                  </form>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <button @click="close" type="button"
                  class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2  text-base font-medium hover:text-gray-900  hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
            Abort
          </button>
          <button @click="submit" type="button"
                  class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm">
            Submit
          </button>
          <button @click="blackout" type="button"
                  v-show="this.$hasPerm('FILE_CONFIDENTIAL_EDIT')"
                  class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-black text-base font-medium text-white hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm">
            Schwärzen
          </button>
          <button @click="deleteFile" type="button"
                  v-show="this.$hasPerm('FILE_EDIT')"
                  class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-500 text-base font-medium text-white hover:bg-red-900 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm">
            Delete
          </button>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import ConfirmModal from "@/components/modals/ConfirmModal";
export default {
  name: "EditFileModal",
  components: {ConfirmModal},
  data() {
    return {
      showModal: false,
      department: JSON.parse(localStorage.user).department,
      imgURL: "https://avatars.githubusercontent.com/u/35440139",
      fileObject: {
        name: "",
        birthdate: "",
        height: 0,
        imgLink: "",
        telnumber: "",
        note: "",
        confidential: false,
      }
    }
  },
  methods: {
    updateImage(){
      this.imgURL = this.fileObject.imgLink;
    },
    show(file) {
      this.fileObject = file;
      this.showModal = true
    },
    close() {
      this.showModal = false
      this.showInfo = false
    },
    blackout(){
      this.fileObject.confidential = !this.fileObject.confidential;
      this.submit()
    },
    deleteFile(){
      this.close();
      this.$refs.deleteFile.show()
    },
    nowDelete(){
      this.fileObject.deleted = true;
      this.submit();
      window.location.href = "/files"
    },
    submit() {

      fetch(this.getAPIHostname() + "/department/" + this.department.id + "/file/" + this.fileObject.id,
          {
            method: "PATCH",
            mode: "cors",
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Basic ' + localStorage.auth
            },
            body: JSON.stringify(this.fileObject)
          }
      ).then(this.jsonParser).then(() => {
        this.$emit("sent")
        this.close();
      })

    }
  }
}
</script>

<style scoped>


</style>
