<template>
  <div class="smallcards">
    <InfoCard
              title="Officer-Anzahl"
              :content="stats.officerCount"
              icon="fa-users"
              color="#DB5461"></InfoCard>
    <InfoCard
              title="Erstellte Akten"
              :content="stats.fileCount"
              icon="fa-file"
              color="#246EB9"></InfoCard>
    <InfoCard
              title="Erstellte Akten-Einträge"
              :content="stats.fileEntryCount"
              icon="fa-pen"
              color="#371E30"></InfoCard>
  </div>
  <TopOfficerTable/>

</template>

<script>
import TopOfficerTable from "@/components/stats/TopOfficerTable";
import InfoCard from "@/components/InfoCard";

export default {
  name: "StatsView",
  components: {InfoCard, TopOfficerTable},
  data() {
    return {
      department: JSON.parse(localStorage.user).department,
      stats: {
        officerCount: "???",
        fileCount: "???",
        fileEntryCount: "???"
      }
    }
  },
  methods: {
    loadData() {
      fetch(this.getAPIHostname() + "/department/" + this.department.id + "/stats/general",
          {
            method: "GET",
            mode: "cors",
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Basic ' + localStorage.auth
            },
          }
      ).then(this.jsonParser)
          .then(json => {
            this.stats = json;
          })
    }
  },
  mounted() {
    this.loadData()
  }
}
</script>

<style scoped>
.smallcards {
  display: flex;
  gap: 10px;
  margin-bottom: 3rem;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  overflow-x: hidden;
}
.smallcards *{
  width: 30%;
}
</style>

