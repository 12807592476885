<template>

  <HelpContainer content="help.wanted" />

  <div class="card">
    <div class="title">{{ $t('homeview.wanted') }}</div>
    <hr>
    <div class="card-content">
      <div class="flex flex-col">
        <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="inline-block py-2 min-w-full sm:px-6 lg:px-8">
            <div class="overflow-hidden shadow-md sm:rounded-lg">

              <table class="min-w-full dark">
                <thead class=" bg-gray-700">
                <tr>
                  <th scope="col"
                      class="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-gray-400">
                    {{ $t('filestable.names') }}
                  </th>
                  <th scope="col"
                      class="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-gray-400">
                    {{ $t('filestable.birth') }}
                  </th>
                  <th scope="col"
                      class="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-gray-400">
                    {{ $t('filestable.phone') }}
                  </th>
                </tr>
                </thead>
                <tbody>

                <tr
                    class="border-b bg-gray-800 border-gray-700 transition hover:bg-red-500"
                    v-for="file in files"
                    :key="file.id"
                    @click="openFile(file.id)"
                >
                  <td class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap text-gray-400">
                    {{ file.name }}
                  </td>
                  <td class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap text-gray-400 key ">
                    {{ file.birthdate }}
                  </td>
                  <td class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap text-gray-400">
                    {{ file.telnumber }}
                  </td>
                </tr>


                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HelpContainer from "@/components/HelpContainer";
export default {
  name: "WantedView",
  components: {HelpContainer},
  data() {
    return {
      files: [],
      department: JSON.parse(localStorage.user).department,
    }
  },
  methods: {
    loadData() {
      fetch(this.getAPIHostname() + "/department/" + this.department.id + "/file/wanted",
          {
            method: "GET",
            mode: "cors",
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Basic ' + localStorage.auth
            },
          }
      ).then(res => {
        if (res.status == 200) {
          return res.json();
        } else if (res.status === 401) {
          this.$notify({
            title: "Error",
            text: "Invalid Login!",
            data: {
              type: 'error'
            }
          });
          localStorage.removeItem('auth')
        } else {
          this.$notify({
            title: "Error",
            text: " ",
            data: {
              type: 'error'
            }
          });
        }
      }).then(json => {
        this.files = json;
      })
    },
    openFile(id) {
      window.location.href = "file/" + id
    }
  },
  mounted() {
    this.loadData();
  }
}
</script>

<style scoped>

</style>
