<template>
  <AddFileEntryModal @sent="loadData" ref="modal" :file-i-d="this.$route.params.id"/>
  <EditFileEntryModal @sent="loadData" ref="editModal"/>
  <EditFileModal ref="editFileModal" @sent="loadData"/>

  <GoBackButton/>

  <div class="flex justify-between w-full">
    <h4 class="pagetitle">{{ $t('fileview.title') }}</h4>
    <div class="flex gap-2">
      <button v-show="this.$hasPerm('FILE_EDIT')" @click="showEditFile()" class="newButton"><i class="fa-solid fa-pen-to-square"></i> {{ $t('filestable.button.edit') }}</button>
    </div>
  </div>

  <div class="flex mb-5 gap-5 w-100">
    <div class="newcard relative w-1/2 p-10">
      <img v-show="file.confidential"
           class="absolute"
           src="@/assets/confidential.png"
           height="150"
           width="300"
           style="top: -5%; left: -5%">

      <div class="flex flex-col">
        <div class="pb-5">
          <img :src="file.imgLink" height="150" width="150" style="border-radius: 1.5rem">
        </div>
        <div class="mb-9">
          <h4 class="font-bold text-xl">{{ file.name }} </h4>
        </div>
        <div>
          <div class="flex gap-7 flex-wrap mb-7">

            <div>
              <div class="objectName">{{ $t('fileview.birth') }}</div>
              <div :class="[file.confidential && !(user.permission === 'CHIEF' || user.permission === 'HIGH_COMMAND') ? 'objectValueBlack' : 'objectValue']">
                {{ file.birthdate }}
              </div>
            </div>
            <div>
              <div class="objectName">{{ $t('fileview.height') }}</div>
              <div :class="[file.confidential && !(user.permission === 'CHIEF' || user.permission === 'HIGH_COMMAND') ? 'objectValueBlack' : 'objectValue']">
                {{ file.height }}
              </div>
            </div>
            <div>
              <div class="objectName">{{ $t('fileview.phone') }}</div>
              <div :class="[file.confidential && !(user.permission === 'CHIEF' || user.permission === 'HIGH_COMMAND') ? 'objectValueBlack' : 'objectValue']">
                {{ file.telnumber }}
              </div>
            </div>

          </div>
        </div>
        <div>
          <div class="objectName">{{ $t('fileview.note') }}</div>
          <div :class="[file.confidential && !(user.permission === 'CHIEF' || user.permission === 'HIGH_COMMAND') ? 'objectValueBlack' : 'objectValue']">
            {{ file.note }}
          </div>
        </div>
      </div>
    </div>

    <div class="w-1/2 h-1/2 grid grid-cols-2 gap-y-0">


      <div class="p-2 w-full">
        <div class="newcard p-5 w-full">
          <h4 class="font-bold mb-2 text-lg">{{ $t('filestable.entries') }}</h4>
          <h3 class="font-bold ml-2 text-3xl">{{ file.fileEntries.length }}</h3>
        </div>
      </div>

      <div class="p-2 w-full h-fit">
        <div class="newcard p-5 w-full">
          <h4 class="font-bold mb-2 text-lg">{{ $t('filestable.wanted') }}</h4>
          <h3 v-show="data.wanted" class="font-bold ml-2 text-3xl text-red">{{ $t('filestable.wantedStr.true') }}</h3>
          <h3 v-show="!data.wanted" class="font-bold ml-2 text-3xl text-green">{{ $t('filestable.wantedStr.false') }}</h3>
        </div>
      </div>


      <div class="p-2 w-full h-fit col-span-2">
        <div class="newcard p-5 w-full">
          <div class="flex gap-10 justify-between">
            <div>
              <h4 class="font-bold mb-2 text-lg">{{ $t('filestable.money') }}</h4>
              <h3 class="font-bold ml-2 text-3xl">{{ data.fee.toLocaleString() }}$</h3>
            </div>
            <div>
              <h4 class="font-bold mb-2 text-lg">{{ $t('filestable.prison') }}</h4>
              <h3 class="font-bold ml-2 text-3xl">{{ data.prison.toLocaleString() }}</h3>
            </div>
            <div>
            </div>
          </div>

        </div>
      </div>


    </div>

  </div>

  <div class="flex justify-between w-full">
    <h4 class="pagetitle">{{ $t('filestable.entries') }}</h4>
    <div class="flex gap-2">
      <button @click="$refs.modal.show()" class="newButton"><i class="fa-solid fa-plus"></i> {{ $t('filestable.button.add') }}</button>
    </div>
  </div>



  <div class="relative">
    <img v-show="file.confidential"
         class="absolute"
         src="@/assets/confidential.png"
         height="150"
         width="300"
         style="top: -5%; left: -5%">
    <div class="card-content">


      <table class="min-w-full dark">
        <thead class=" bg-gray-700">
        <tr>
          <th scope="col"
              class="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-gray-400">
            Grund
          </th>
          <th scope="col"
              class="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-gray-400">
            Officer
          </th>
          <th scope="col"
              class="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-gray-400">
            Datum
          </th>
          <th scope="col"
              class="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-gray-400">
            Gefängnis
          </th>
          <th scope="col"
              class="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-gray-400">
            Geldstrafe
          </th>
        </tr>
        </thead>
        <tbody>

        <tr
            @click="$refs.editModal.show(entry, file.id)"
            :class="['border-b bg-gray-800 border-gray-700', entry.wanted ? 'blink' : '']"
            v-for="entry in file.fileEntries" :key="entry.id"
        >
          <td :class="['py-4 px-6 text-sm text-gray-500 whitespace-nowrap']">
            <span :class="[file.confidential && !(user.permission === 'CHIEF' || user.permission === 'HIGH_COMMAND') ? 'bg-black text-black rounded' : 'text-white']">
            {{ entry.reason }}
            </span>
          </td>
          <td class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap text-white">
            <span :class="[file.confidential && !(user.permission === 'CHIEF' || user.permission === 'HIGH_COMMAND') ? 'bg-black text-black rounded' : 'text-white']">
            {{ entry.copUser.badgeNumber + "-" + entry.copUser.name }}
            </span>
          </td>
          <td class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap text-white">
            <span :class="[file.confidential && !(user.permission === 'CHIEF' || user.permission === 'HIGH_COMMAND') ? 'bg-black text-black rounded' : 'text-white']">
            {{ toDate(entry.timestamp) }}
            </span>
          </td>
          <td class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap text-white">
            <span :class="[file.confidential && !(user.permission === 'CHIEF' || user.permission === 'HIGH_COMMAND') ? 'bg-black text-black rounded' : 'text-white']">
            {{ entry.prisonTime }}
            </span>
          </td>
          <td class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap text-white">
            <span :class="[file.confidential && !(user.permission === 'CHIEF' || user.permission === 'HIGH_COMMAND') ? 'bg-black text-black rounded' : 'text-white']">
            {{ entry.billAmount }}
            </span>
          </td>
        </tr>

        </tbody>
      </table>


    </div>
  </div>
</template>

<script>
import AddFileEntryModal from "@/components/AddFileEntryModal";
import EditFileEntryModal from "@/components/EditFileEntryModal";
import EditFileModal from "@/components/EditFileModal";
import GoBackButton from "@/components/GoBackButton";

export default {
  name: "FileView",
  components: {
    GoBackButton,
    EditFileModal,
    AddFileEntryModal,
    EditFileEntryModal,
  },
  data() {
    return {
      department: JSON.parse(localStorage.user).department,
      user: JSON.parse(localStorage.user),
      data: {
        wanted: false,
        prison: 0,
        fee: 0
      },
      file: {
        name: "Name",
        birthdate: "00.00.00",
        height: 180,
        imgLink: "https://avatars.githubusercontent.com/u/35440139",
        telnumber: "000000",
        fileEntries: [],
        confidential: false,
      },
    }
  },
  methods: {
    toDate(millies) {
      return new Date(millies).toLocaleString()
    },
    showEditFile() {
      this.$refs.editFileModal.show(this.file)
    },
    loadData() {
      this.data = {
        wanted: false,
        prison: 0,
        fee: 0
      }

      fetch(this.getAPIHostname() + "/department/" + this.department.id + "/file/" + this.$route.params.id,
          {
            method: "GET",
            mode: "cors",
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Basic ' + localStorage.auth
            },
          }
      ).then(this.jsonParser).then(json => {
        this.file = json;

        for (let entry in this.file.fileEntries){
          if(this.file.fileEntries[entry].wanted) this.data.wanted = true;
          this.data.prison += this.file.fileEntries[entry].prisonTime
          this.data.fee += this.file.fileEntries[entry].billAmount
        }

      })
    }
  },
  mounted() {
    this.loadData();
  }
}
</script>

<style scoped>
.nexusRow {
  display: flex;
  gap: 1rem;
}

.blink {
  animation: blink 2s;
  animation-iteration-count: infinite;
  background-color: rgb(156 163 175);
}

@keyframes blink {
  0% {
    background-color: #2B3545;
  }
  50% {
    background-color: var(--red);
  }
  100% {
    background-color: #2B3545;
  }
}

.staticButton {
  z-index: 99;
  position: fixed;
  bottom: 3rem;
  right: 3rem;
  background-color: #59CD90;
  padding: 2rem;
  border-radius: 50%;
  transition: 500ms;
}

.staticButton:hover {
  transform: scale(1.2);
}
</style>
