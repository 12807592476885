<template>
  <HelpContainer content="help.officerManagement" />

  <AddCopUserModal ref="modal" @sent="loadData" :admin=false />
  <EditCopUserModal ref="edit" @sent="loadData" />

  <div class="card">
    <div class="title-row flex flex-row justify-between">
      <div  class="title">{{ $t('ofcmanagement.title') }}</div> <button @click="openModal()" class="btn green m-2" >{{ $t('ofcmanagement.addbtn') }}</button>
    </div>
    <hr>
    <div class="card-content">


      <table class="min-w-full dark">
        <thead class=" bg-gray-700">
        <tr>
          <th scope="col"
              class="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-gray-400">
            {{ $t('ofcmanagement.badge') }}
          </th>
          <th scope="col"
              class="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-gray-400">
            Name
          </th>
          <th scope="col"
              class="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-gray-400">
            Rang
          </th>
          <th scope="col"
              class="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-gray-400">
              {{ $t('ofcmanagement.delete') }}
          </th>
        </tr>
        </thead>
        <tbody>

        <tr
            class="border-b bg-gray-800 border-gray-700"
            v-for="chief in officers" :key="chief.id"
        >
          <td class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap text-white" @click="openOfficer(chief.badgeNumber)">
            {{ chief.badgeNumber }}
          </td>
          <td class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap text-white" @click="openOfficer(chief.badgeNumber)">
            {{  chief.name  }}
          </td>
          <td class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap text-white">
            {{(chief.permissionGroup ? chief.permissionGroup.name : 'n/a')}}
          </td>
          <td class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap text-white">
            <button @click="openEdit(chief)" class="btn">{{ $t('ofcmanagement.edit') }}</button>
          </td>
        </tr>
        </tbody>
      </table>



    </div>
  </div>
</template>

<script>
import AddCopUserModal from "@/components/AddCopUserModal";
import EditCopUserModal from "@/components/EditCopUserModal";
import HelpContainer from "@/components/HelpContainer";

export default {
  name: "OfficerManagementView",
  components: {
    HelpContainer,
    EditCopUserModal,
    AddCopUserModal
  },
  data(){
    return{
      officers: [],
      department: JSON.parse(localStorage.user).department,
    }
  },
  methods: {
    openModal(){
      this.$refs.modal.show();
    },
    loadData(){
      fetch(this.getAPIHostname() + "/department/" + this.department.id + "/cop/",
          {
            method: "GET",
            mode: "cors",
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Basic ' + localStorage.auth
            },
          }
      ).then(res => {
        if (res.status == 200){
          return res.json();
        }
        else {
          this.$notify({
            title: "Error",
            text: " ",
            data: {
              type: 'error'
            }
          });
        }
      }).then(json => {
        this.officers = json;
      })
    },
    openEdit(copUser){
    this.$refs.edit.show(copUser)
    },
    openOfficer(id){
      window.location.href = "copuser/" + id
    },
  },
  mounted() {
    this.loadData()
  }
}
</script>

<style scoped>

</style>
