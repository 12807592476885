import { createRouter, createWebHistory } from 'vue-router'

import routes from './routes.js'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach((to, from, next) => {

  if(to.path === '/user/login' || to.path === '/login' || to.path === '/logout' || to.path === '/'){
    next();
  }else if(to.path !== '/user/login' && to.path !== '/login' && to.path !== '/' && (localStorage.auth == null || localStorage.user == null)){
    next('/login')
  }else {
    let user = JSON.parse(localStorage.user);
    if(user.badgeNumber == null && to.path !== '/user/department'){
      next('/user/department')
    }else {
      next();
    }
  }
})

export default router
