<template>

  <DiscordCard />
  <div class="card">
    <div class="title-row">
      <div class="title"><b>Login</b></div>
    </div>
    <hr>
    <div class="card-content">

        <form>
          <div class="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
            <div>
              <label class="text-gray-200" for="loginUsername">Badge</label>
              <input id="loginUsername"
                     v-model="combined"
                     type="text"
                     class="block w-full px-4 py-2 mt-2 text-gray-700 border border-gray-200 rounded-md bg-gray-800 text-gray-300 border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 focus:border-blue-300 focus:outline-none focus:ring">
            </div>


            <div>
              <label class="text-gray-200" for="loginPassword">Password</label>
              <input id="loginPassword"
                     v-model="loginObject.password"
                     type="password"
                     class="block w-full px-4 py-2 mt-2 text-gray-700 border border-gray-200 rounded-md bg-gray-800 text-gray-300 border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 focus:border-blue-300 focus:outline-none focus:ring">
            </div>

          </div>
          <div class="flex justify-end mt-6">
            <button @click="submitLogin()"
                    type="button"
                    class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
              Login
            </button>
          </div>
        </form>
      <div style="position: relative; height: 1rem">
        <router-link to="/user/login" style="position: absolute; right: 0" class="text-blue-600">Register as Server Owner? <i class="fa-solid fa-arrow-up-right-from-square"></i></router-link>
      </div>



    </div>
  </div>
</template>

<script>
import DiscordCard from "@/components/DiscordCard";
export default {
  name: "CopLoginView",
  components: {DiscordCard},
  data() {
    return{
      combined: "",
      loginObject: {
        badge: "",
        department: 0,
        password: ""
      }
    }
  },
  methods: {
    submitLogin(){
      let arr = this.combined.split(/-(.*)/s);
      this.loginObject.badge = arr[1];
      this.loginObject.department = arr[0];

      let auth = btoa(JSON.stringify(this.loginObject))


      fetch(this.getAPIHostname() + "/department/" + this.loginObject.department + "/cop/" + this.loginObject.badge,
          {
            method: "GET",
            mode: "cors",
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Basic ' + auth
            }
          }
      ).then(res => {
        if (res.status == 200) {
          return res.json();
        }else if(res.status === 401){
          this.$notify({
            title: "Error",
            text: "Invalid Login!",
            data: {
              type: 'error'
            }
          });
          localStorage.removeItem('auth')
          return;
        } else {
          this.$notify({
            title: "Error",
            text: " ",
            data: {
              type: 'error'
            }
          });
          return;
        }
      }).then(json =>{
        if(!json){
          return;
        }
        localStorage.user = JSON.stringify(json);
          localStorage.auth = auth;
        this.$notify({
          title: "Success",
          text: "",
          data: {
            type: 'success'
          }
        });
        setTimeout(function (){
          window.location.href = 'home';
        }, 1000)

      });

    }
  }
}
</script>

<style scoped>

</style>
