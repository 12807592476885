<template>
  <div id="notifiations" class="notification-container absolute bg-gray-900 rounded p-2" v-show="show">
    <div class="notification" v-for="notification in notifications" :key="notification.id">
      <span class="title">{{notification.title}}</span>
      <span class="message">{{notification.message}}</span>
      <span class="timestamp">{{notification.timestamp}}</span>
    </div>
  </div>

</template>

<script>
export default {
  name: "NotificationsContainer",
  data() {
    return {
      show: false,
    }
  },
  props:{
    notifications: {type: Array},
  },
  mounted() {
  },
  methods: {
    close(){
      this.show = false;
    },
    open(){
      this.show = true;
    }
  }
}
</script>

<style scoped>

.deactivator{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  z-index: 103;
}

.notification-container{
  z-index: 104;
  min-width: 30em;
}
</style>
