<template>
  <AddFileModal ref="modal"></AddFileModal>
  <button class="staticButton" @click="$refs.modal.show()"><i class="fa-solid fa-plus"></i></button>


  <div class="card">
    <div class="title-row">
      <div class="title">{{ $t("filestable.ttitle") }}</div>
      <form v-on:submit.prevent @submit="loadData">
        <input v-model="query" id="key" type="text"
               class="block w-full px-4 py-2 mt-2 text-gray-700 border border-gray-200 rounded-md bg-gray-800 text-gray-300 border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 focus:border-blue-300 focus:outline-none focus:ring"
               placeholder="Suche...">
      </form>
    </div>
    <hr>
    <div class="card-content">
      <div class="flex flex-col">
        <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="inline-block py-2 min-w-full sm:px-6 lg:px-8">
            <div class="overflow-hidden shadow-md sm:rounded-lg">

              <table class="min-w-full dark">
                <thead class=" bg-gray-700">
                <tr>
                  <th scope="col"
                      class="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-gray-400">
                    {{ $t('filestable.names') }}
                  </th>
                  <th scope="col"
                      class="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-gray-400">
                    {{ $t('filestable.birth') }}
                  </th>
                  <th scope="col"
                      class="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-gray-400">
                    {{ $t('filestable.phone') }}
                  </th>
                </tr>
                </thead>
                <tbody>

                <tr
                    class="border-b bg-gray-800 border-gray-700 transition hover:bg-red-500"
                    v-for="file in files"
                    :key="file.id"
                    @click="openFile(file.id)"
                >
                  <td class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap text-gray-400">
                    {{ file.name }}
                  </td>
                  <td class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap text-gray-400 key ">
                    {{ file.birthdate }}
                  </td>
                  <td class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap text-gray-400">
                    {{ file.telnumber }}
                  </td>
                </tr>


                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import AddFileModal from "@/components/AddFileModal";

export default {
  name: "FilesView",
  components: {
    AddFileModal
  },
  data() {
    return {
      files: [],
      department: JSON.parse(localStorage.user).department,
      query: ""
    }
  },
  methods: {
    loadData() {
      fetch(this.getAPIHostname() + "/department/" + this.department.id + "/file/?query=" + this.query,
          {
            method: "GET",
            mode: "cors",
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Basic ' + localStorage.auth
            },
          }
      ).then(res => {
        if (res.status == 200) {
          return res.json();
        } else if (res.status === 401) {
          this.$notify({
            title: "Error",
            text: "Invalid Login!",
            data: {
              type: 'error'
            }
          });
          localStorage.removeItem('auth')
        } else {
          this.$notify({
            title: "Error",
            text: " ",
            data: {
              type: 'error'
            }
          });
        }
      }).then(json => {
        this.files = json;
      })
    },
    openFile(id) {
      window.location.href = "file/" + id
    }
  },
  mounted() {
    this.loadData();
  }
}
</script>

<style scoped>
.ttable {
  margin-top: 10%;
  margin-left: -30%;
  font-size: 300%;
}

.title-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title-row > *:last-child {
  margin: 1rem;
}

.staticButton {
  position: fixed;
  bottom: 3rem;
  right: 3rem;
  background-color: #59CD90;
  padding: 2rem;
  border-radius: 50%;
  transition: 500ms;
}

.staticButton:hover {
  transform: scale(1.2);
}
</style>
