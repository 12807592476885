<template>
<DiscordCard />
  <div class="card">
    <div class="title-row flex flex-row justify-between mb-3 mr-3">
      <div class="title">{{ $t('logview.title') }}</div>

      <form class="flex flex-row gap-5" v-on:submit.prevent @submit="loadData">
        <input v-model="badge" id="key" @blur="loadData" type="text"
               class="block w-full px-4 py-2 mt-2 text-gray-700 border border-gray-200 rounded-md bg-gray-800 text-gray-300 border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 focus:border-blue-300 focus:outline-none focus:ring"
               placeholder="Dienstnummer">
        <input v-model="fileid" id="kek" @blur="loadData" type="text"
               class="block w-full px-4 py-2 mt-2 text-gray-700 border border-gray-200 rounded-md bg-gray-800 text-gray-300 border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 focus:border-blue-300 focus:outline-none focus:ring"
               placeholder="Akten-ID">
      </form>
    </div>
    <hr>
    <div class="card-content">


      <table class="min-w-full dark table-fixed">
        <thead class=" bg-gray-700">
        <tr>
          <th scope="col"
              class="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-gray-400">
          {{ $t('logview.action') }}
          </th>
          <th scope="col"
              class="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-gray-400">
          {{ $t('logview.officer') }}
          </th>
          <th scope="col"
              class="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-gray-400">
          {{ $t('logview.desc') }}
          </th>
          <th scope="col"
              class="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-gray-400">
          {{ $t('logview.date') }}
          </th>
          <th scope="col"
              class="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-gray-400">
          {{ $t('logview.file') }}
          </th>
        </tr>
        </thead>
        <tbody>
        <tr
            v-for="entry in list"
            :key="entry.id"
            class="border-b bg-gray-800 border-gray-700">
          <td class="py-4 px-6 text-sm text-gray-500">{{entry.name}}</td>
          <td class="py-4 px-6 text-sm text-gray-500">{{entry.copUser.badgeNumber + "-" + entry.copUser.name}}</td>
          <td class="py-4 px-6 text-sm text-gray-500">{{entry.description}}</td>
          <td class="py-4 px-6 text-sm text-gray-500">{{toDate(entry.timestamp)}}</td>
          <td class="py-4 px-6 text-sm text-gray-500" v-html="entry.file == null ? '-' : '<a href=\'/file/' + entry.file.id + '\'>' + entry.file.name + '</a>'"></td>
        </tr>


        </tbody>
      </table>


    </div>
  </div>
</template>

<script>
import DiscordCard from "@/components/DiscordCard";
export default {
  name: "LogView",
  components: {DiscordCard},
  data() {
    return {
      department: JSON.parse(localStorage.user).department,
      list: [],
      badge: '',
      fileid: '',
    }
  },
  methods: {
    toDate(millies){
      return new Date(millies).toLocaleString()
    },
    loadData() {
      fetch(this.getAPIHostname() + "/department/" + this.department.id + "/log/?badge=" + this.badge + (this.fileid === '' || this.fileid === ' ' ? '' : '&fileID=' + this.fileid),
          {
            method: "GET",
            mode: "cors",
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Basic ' + localStorage.auth
            },
          }
      )
          .then(this.jsonParser)
          .then(
              json =>{
                this.list = json;
              }
          )
    }
  },
  mounted() {
    this.loadData();
  }
}
</script>

<style scoped>

</style>
