import HomeView from "@/views/HomeView";
import FilesView from "@/views/FilesView";
import WantedView from "@/views/WantedView";
import LoginView from "@/views/user/LoginView";
import DepartmentSettingsView from "@/views/user/DepartmentSettingsView";
import CopLoginView from "@/views/CopLoginView";
import FileView from "@/views/FileView";
import LawView from "@/views/LawView";
import LogoutView from "@/views/LogoutView";
import OfficerManagementView from "@/views/OfficerManagementView";
import LogView from "@/views/LogView";
import StatsView from "@/views/StatsView";
import CopUserView from "@/views/CopUserView";
import CaseFilesView from "@/views/CaseFilesView";
import CaseView from "@/views/CaseView";
import LandingView from "@/views/LandingView";
import SettingsView from "@/views/SettingsView";
import PatrolView from "@/views/patrolcontroll/PatrolView";
import PatrolControllView from "@/views/patrolcontroll/PatrolControllView";
import GlobalSettingsView from "@/views/GlobalSettingsView";


const routes = [
    {
        path: '/',
        name: 'landing',
        component: LandingView,
        displayName: 'navbar.home',
        icon: 'fa-solid fa-house-user',
        permission: null,
        splitBefore: false,
        display: false
    },
    {
        path: '/home',
        name: 'home',
        component: HomeView,
        displayName: 'navbar.home',
        icon: 'fa-solid fa-house-user',
        permission: null,
        splitBefore: true,
        display: true
    },
    {
        path: '/files',
        name: 'files',
        component: FilesView,
        displayName: 'navbar.files',
        icon: 'fa-solid fa-file',
        permission: "FILE_VIEW",
        splitBefore: true,
        display: true
    },
    {
        path: '/file/:id',
        name: 'file',
        component: FileView,
        displayName: 'navbar.files',
        icon: 'fa-solid fa-file',
        permission: "FILE_VIEW",
        splitBefore: false,
        display: false
    },
    {
        path: '/copuser/:id',
        name: 'copuser',
        component: CopUserView,
        displayName: 'navbar.files',
        icon: 'fa-solid fa-file',
        permission: "OFFICER_VIEW",
        splitBefore: false,
        display: false
    },
    {
        path: '/wanted',
        name: 'wanted',
        component: WantedView,
        displayName: 'navbar.wanted',
        icon: 'fa-solid fa-handcuffs',
        permission: "FILE_VIEW",
        splitBefore: false,
        display: true
    },
    {
        path: '/patrol',
        name: 'patrol',
        component: PatrolView,
        displayName: 'navbar.patrol',
        icon: 'fa-solid fa-phone-flip',
        permission: "PATROL_USE",
        splitBefore: false,
        display: true
    },
    {
        path: '/patrolcontroll',
        name: 'patrolcontroll',
        component: PatrolControllView,
        displayName: 'navbar.patrol',
        icon: 'fa-solid fa-phone-flip',
        permission: "PATROL_CONTROLLER",
        splitBefore: false,
        display: false
    },
    {
        path: '/user/login',
        name: 'login',
        component: LoginView,
        displayName: 'navbar.login',
        icon: 'fa-solid fa-phone-flip',
        permission: null,
        splitBefore: false,
        display: false
    },
    {
        path: '/user/department',
        name: 'departmentsettings',
        component: DepartmentSettingsView,
        displayName: 'navbar.login',
        icon: 'fa-solid fa-phone-flip',
        permission: 3,
        splitBefore: false,
        display: false
    },
    {
        path: '/login',
        name: 'coplogin',
        component: CopLoginView,
        displayName: 'navbar.login',
        icon: 'fa-solid fa-phone-flip',
        permission: null,
        splitBefore: false,
        display: false
    },
    {
        path: '/laws',
        name: 'lawes',
        component: LawView,
        displayName: 'navbar.laws',
        icon: 'fa-solid fa-book',
        permission: "LAW_VIEW",
        splitBefore: false,
        display: true
    },
    {
        path: '/officers',
        name: 'officers',
        component: OfficerManagementView,
        displayName: 'navbar.officers',
        icon: 'fa-solid fa-building-shield',
        permission: "OFFICER_VIEW",
        splitBefore: true,
        display: true
    },
    {
        path: '/log',
        name: 'log',
        component: LogView,
        displayName: 'navbar.log',
        icon: 'fa-solid fa-list',
        permission: "LOG_VIEW",
        splitBefore: false,
        display: true
    },
    {
        path: '/stats',
        name: 'stats',
        component: StatsView,
        displayName: 'navbar.stats',
        icon: 'fa-solid fa-chart-bar',
        permission: "STATS_VIEW",
        splitBefore: false,
        display: true
    },
    {
        path: '/cases',
        name: 'cases',
        component: CaseFilesView,
        displayName: 'navbar.cases',
        icon: 'fa-solid fa-magnifying-glass',
        permission: "CASEFILE_VIEW",
        splitBefore: true,
        display: true
    },
    {
        path: '/casefile/:id',
        name: 'case',
        component: CaseView,
        displayName: 'navbar.cases',
        icon: 'fa-solid fa-magnifying-glass',
        permission: "CASEFILE_VIEW",
        splitBefore: false,
        display: false
    },
    {
        path: '/settings',
        name: 'settings',
        component: SettingsView,
        displayName: 'navbar.settings',
        icon: 'fa-solid fa-cogs',
        permission: null,
        splitBefore: true,
        display: true
    },
    {
        path: '/departmentsettings',
        name: 'settings',
        component: GlobalSettingsView,
        displayName: 'navbar.deparmentsettings',
        icon: 'fa-solid fa-cogs',
        permission: "PERMISSION_EDIT",
        splitBefore: true,
        display: true
    },
    {
        path: '/logout',
        name: 'logout',
        component: LogoutView,
        displayName: 'navbar.logout',
        icon: 'fa-solid fa-arrow-right-from-bracket',
        permission: null,
        splitBefore: true,
        display: true
    },

]


export default routes
