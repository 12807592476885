export default {
  "host": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://api.lspdnet.com"])},
  "navbar": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akten"])},
    "wanted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fahndungen"])},
    "patrol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Streife"])},
    "laws": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesetz-Management"])},
    "officers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitarbeiter-Management"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
    "log": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logs"])},
    "cases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fall-Akten"])},
    "stats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistiken"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einstellungen"])},
    "deparmentsettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Globale Einstellungen"])}
  },
  "ui": {
    "goback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zurück"])}
  },
  "homeview": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fahndungen"])},
    "announcement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ankündigungen"])},
    "addannouncement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ankündigung erstellen"])},
    "deleteannouncement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
    "wanted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesuchte Personen"])}
  },
  "filesview": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akten"])},
    "addModal": {
      "selector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Straftaten"])},
      "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geldstrafe"])},
      "prison": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gefängnisstrafe"])}
    }
  },
  "addfilemodal": {
    "addfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akte erstellen"])},
    "birth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geburtsdatum"])},
    "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Größe"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tel.-Nummer"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen"])},
    "abort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])}
  },
  "lawview": {
    "addbtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinzufügen"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])}
  },
  "ofcmanagement": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitarbeiter"])},
    "addbtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitarbeiter hinzufügen"])},
    "badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienstnummer"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeiten"])}
  },
  "announcement": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ankündigung erstellen"])},
    "announcement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ankündigung"])},
    "abort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigen"])}
  },
  "logview": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logs"])},
    "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktion"])},
    "officer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beamter"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
    "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akte"])}
  },
  "stats": {
    "badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienstnummer"])}
  },
  "addlawbookmodal": {
    "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesetzbuch hinzufügen"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titel"])}
  },
  "addlawmodal": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titel"])},
    "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eintrag hinzufügen"])},
    "fine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geldstrafe"])},
    "prison": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gefängnisstrafe"])}
  },
  "editcmodal": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeiten"])},
    "badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienstnummer"])},
    "abort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigen"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort zurücksetzen"])},
    "fire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feuern"])}
  },
  "buttons": {
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigen"])},
    "abort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])}
  },
  "filestable": {
    "ttitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akten"])},
    "entries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akteneinträge"])},
    "names": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "birth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geburtsdatum"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tel.-Nummer"])},
    "wanted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesucht?"])},
    "money": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geldstrafe (gesamt)"])},
    "prison": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gefängnisstrafe (gesamt)"])},
    "wantedStr": {
      "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
      "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nein"])}
    },
    "button": {
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akte bearbeiten"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akteneintrag hinzufügen"])}
    }
  },
  "patrolview": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Streife"])}
  },
  "fileview": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akte"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Höhe"])},
    "birth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geburtsdatum"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tel.-Nummer"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notiz"])}
  },
  "login": {
    "error": {
      "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Benutzername muss min. 3 Zeichen lang sein."])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die E-Mail Adresse ist ungültig!"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Passwörter stimmen nicht überein!!"])}
    }
  },
  "help": {
    "wanted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Hier werden alle Bürger aufgelistet, bei denen ein oder mehr Einträge nach Fahndung ausgeschrieben sind.</p>"])},
    "law": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Hier können Gesetzte hinzugefügt werden. <br>Zunächst muss ein Gesetzbuch hinzugefügt werden, um diesem dann Gesetzte beizufügen.</p>"])},
    "officerManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Hier können Mitarbeiter hinzugefügt und verwaltet werden.<br>Das Passwort wird bei Erstellung des Accounts   einmalig angezeigt. Danach kann nur zurückgesetzt werden. <br>Die Gruppen sind mit den Berechtigungen wie folgt   aufgeteilt: </p><br><b>CHIEF</b><ul>  <li>- Akten Schwärzen</li></ul><br> <b>HIGH_COMMAND</b><ul>  <li>- Geschwärzte Akten einsehen</li>  <li>- Gesetzte bearbeiten</li></ul><br> <b>MID_COMMAND</b><ul>  <li>- Akten-Einträge löschen</li>  <li>- Mitarbeiter Erstellen</li>  <li>- Mitarbeiter Bearbeiten</li>  <li>- Mitarbeiter Löschen</li></ul><br> <b>LOW_COMMAND</b><ul>  <li>- Akten bearbeiten</li></ul><br> <b>OFFICER</b><ul>  <li>- Standard-Berechtigungen (Akten erstellen, einsehen, etc...)</li></ul><br> <b>SUSPENDED</b><ul>  <li>- Keine Berechtigungen</li></ul>"])}
  },
  "discord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joine unserem Discord für Updates und wichtige Informationen.<br>Wenn du ein Problem oder ein Vorschlag hast, ist der Discordserver auch die perfekte Anlaufstelle!<br><br><a href='https://discord.gg/vAUcMggvdH'>https://discord.gg/vAUcMggvdH</a>"])},
  "permission": {
    "ANNOUNCEMENT_ADD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ankündigung erstellen"])},
    "ANNOUNCEMENT_REMOVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ankündigung löschen"])},
    "CASEFILE_ADD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fall-Akte erstellen"])},
    "CASEFILE_VIEW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fall-Akte einsehen"])},
    "CASEFILE_CONFIDENTIAL_VIEW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschwärzte Fall-Akte einsehen"])},
    "CASEFILE_CONFIDENTIAL_EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fall-Akten schwärzen"])},
    "OFFICER_EDIT_CREATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitarbeiter erstellen"])},
    "OFFICER_EDIT_DELETE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitarbeiter feuern"])},
    "OFFICER_EDIT_EDIT_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitarbeiter Passwort zurücksetzen"])},
    "OFFICER_EDIT_EDIT_RANK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitarbeiter Rang bearbeiten"])},
    "OFFICER_VIEW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitarbeiter einsehen"])},
    "PERMISSION_EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berechtigungen bearbeiten"])},
    "FILE_VIEW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akte einsehen"])},
    "FILE_CREATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akte erstellen"])},
    "FILE_EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akte bearbeiten"])},
    "FILE_DELETE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akte löschen"])},
    "FILE_CONFIDENTIAL_EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akte schwärzen"])},
    "FILE_CONFIDENTIAL_VIEW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschwärzte Akte einsehen"])},
    "FILE_ENTRY_CREATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akten-Eintrag erstellen"])},
    "FILE_ENTRY_DELETE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akten-Eintrag löschen"])},
    "FILE_ENTRY_EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akten-Eintrag bearbeiten"])},
    "LAW_VIEW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesetze einsehen"])},
    "LAW_ADD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesetze hinzufügen"])},
    "LAW_DELETE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesetze löschen"])},
    "LOG_VIEW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logs einsehen"])},
    "PATROL_CONTROLLER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leitstelle"])},
    "PATROL_USE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Streifensystem nutzen"])},
    "PATROL_EDIT_PATROL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Streifen hinzufügen"])},
    "PATROL_EDIT_CAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fahrzeuge hinzufügen"])},
    "PATROL_EDIT_CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statuscodes hinzufügen"])},
    "STATS_VIEW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistiken einsehen"])},
    "GLOBALSETTINGS_DISCORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discord-Bot einstellen"])}
  }
}