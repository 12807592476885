<template>
  <div :class="[collapsed ? 'collapse' : '', ' flex fixed items-start self-start top-3 right-0 ease-in duration-200 z-50']">
    <div class="iconContainer rounded-l" @click="collapsed = !collapsed">
      <i class="fa-solid fa-circle-question"></i>
    </div>
    <div class="textContainer rounded-bl" v-html="$t(content)"/>

  </div>
</template>

<script>
export default {
  name: "HelpContainer",
  data() {
    return {
      collapsed: true
    }
  },
  props: {
    content: String
  }
}
</script>

<style scoped>


.iconContainer {
  background-color: #4CC9F0;
  @apply p-5
}

.textContainer {
  background-color: #246EB9;
  width: 18rem;
  max-height: 70vh;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  @apply p-5
}

.textContainer::-webkit-scrollbar{
  display: none;
}

.collapse {

  margin-right: -18rem;
}

</style>
