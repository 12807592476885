<template>
  <div class="topbar bg-gray-800 flex justify-between">

    <div class="flex pr-1">
      <div class="buttonContainer flex justify-center">
        <button class="switchButton rounded-md text-xl text-gray-400 px-4 py-1" @click="$emit('collapse')">
          <i class="fa-solid fa-bars"></i></button>
      </div>
      <img class="side-icon" height="47" width="200" src="@/assets/img/Textlogo-Icon.svg">
    </div>

    <div class="aktenfinder flex py-4" v-show="localStorage && (typeof(localStorage.auth) != 'undefined') && !$route.path.startsWith('/user')">
      <FileSearchComponent/>
    </div>

    <div class="topbar-content flex gap-4">
      <div class="relative mr-8 hover:bg-gray-600 p-1 px-2 rounded-full" v-show="false">
        <NotificationsContainer class="notificationsContainer" :notifications="notifications" ref="notifications"/>
        <button class="focus:outline-none" @click="$refs.notifications.show = !$refs.notifications.show" @focusout="$refs.notifications.close()">
          <i  :class="[notifications.length > 0 ? 'fa' : 'far', 'text-4xl fa-bell']"></i>
          <span v-show="notifications.length > 0" class="lable absolute bg-red-800">{{notifications.length}}</span>
        </button>
      </div>
      <div class="name">{{ user.badgeNumber }}-{{ user.name }}</div>
    </div>

  </div>
</template>

<script>
import FileSearchComponent from "@/components/searchbar/FileSearchComponent";
import NotificationsContainer from "@/components/NotificationsContainer";

export default {
  name: "TopBar",
  components: {NotificationsContainer, FileSearchComponent},
  data() {
    return {
      department: null,
      user: {name: "Loading...", badgeNumber: "Loading..."},
      notifications: [
        {
          id: 1,
          title: "Test",
          message: "Dies ist eine Test-Notification Lol xd kekw",
          timestamp: 1590000000000,
          read: false
        },
        {
          id: 2,
          title: "Test",
          message: "Dies auch ahahhadhgshfgs Wasn hier looos",
          timestamp: 1590000000000,
          read: false
        }
      ]
    }
  },
  mounted() {
    try {
      this.department = JSON.parse(localStorage.user).department;
      this.user = JSON.parse(localStorage.user);
    } catch (ignored) {
      //ignore
    }
  }
}
</script>

<style scoped>

.topbar {
  width: 100vw;
  height: 4rem;
  position: fixed;
  top: 0;
  left: 0;

  z-index: 100;
}

.topbar-content {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  padding-right: 2vw;
}

.buttonContainer {
  width: 5rem;
}

.switchButton {
}

.switchButton:focus {
  border: none;
  outline: none;
}

.switchButton:hover {
  color: white;
}

.aktenfinder {
  z-index: 102;
}

.lable{
  top: -0.3rem;
  right: -0.8rem;
  padding: 0.1rem 0.7rem;
  border-radius: 1rem;
}

.notificationsContainer{
  top: 4em;
  right: 0;
}

</style>
