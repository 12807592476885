<template>


  <img class="img1" src="https://uploads-ssl.webflow.com/6003416b0aa4a84bf3bfbd91/6003416b0aa4a81b2bbfbe3b_rec3.png" alt="img1">
  <img src="https://uploads-ssl.webflow.com/6003416b0aa4a84bf3bfbd91/6003416b0aa4a80956bfbe3d_rec1.png" alt="img3" class="img3">


  <div class="flex justify-center flex-col items-center mt-20 gap-5">
  <h1 class="text-red-500 font-extrabold my-4">OOC-Login</h1>

    <div :class="['logincard p-5 rounded-xl px-10 flex flex-col items-center relative', login ? 'w-1/4' : 'w-1/3']">
      <a class="text-white text-5xl font-extrabold" href="#"><img src="@/assets/img/Textlogo-Icon.svg" width="350" alt=""></a>

      <form class="mt-8 w-full" v-show="login">

        <div class="my-5 flex items-center gap-5 justify-center w-full">
          <label class="text-red-500 font-extrabold" for="loginUsername"><i class="fa-solid fa-user"></i></label>
          <input id="loginUsername"
                 v-model="loginForm.username"
                 type="text"
                 placeholder="Username"
                 class="bg-transparent text-white p-2 border-gray-600 border-b-2 focus:border-gray-600 border-b-2 focus:outline-none w-full">
        </div>

        <div class="my-5 flex items-center gap-5 justify-center w-full">
          <label class="text-red-500 font-extrabold" for="loginPassword"><i class="fa-solid fa-key"></i></label>
          <input id="loginPassword"
                 v-model="loginForm.password"
                 type="password"
                 placeholder="Password"
                 class="bg-transparent text-white p-2 border-gray-600 border-b-2 focus:border-gray-600 border-b-2 focus:outline-none w-full">
        </div>


        <div class="flex justify-start mt-6">
          <button @click="submitLogin()"
                  type="button"
                  class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
            Login
          </button>
        </div>

        <p @click="toggle()" class="underline text-gray-400 mt-5 hover:cursor-pointer">Or create new User</p>

      </form>
      <form class="mt-8 w-full" v-show="!login">


        <div class="grid grid-cols-2 gap-y-1 gap-x-5">
          <div class="my-5 flex items-center gap-5 justify-center w-full">
            <label class="text-red-500 font-extrabold" for="registername"><i class="fa-solid fa-user"></i></label>
            <input id="registername"
                   v-model="registerForm.username"
                   type="text"
                   placeholder="Username"
                   class="bg-transparent text-white p-2 border-gray-600 border-b-2 focus:border-gray-600 border-b-2 focus:outline-none w-full">
          </div>
          <div class="my-5 flex items-center gap-5 justify-center w-full">
            <label class="text-red-500 font-extrabold" for="registermail"><i class="fa-solid fa-at"></i></label>
            <input id="registermail"
                   v-model="registerForm.email"
                   type="text"
                   placeholder="E-Mail"
                   class="bg-transparent text-white p-2 border-gray-600 border-b-2 focus:border-gray-600 border-b-2 focus:outline-none w-full">
          </div>

          <div class="my-5 flex items-center gap-5 justify-center w-full">
            <label class="text-red-500 font-extrabold" for="registerpw"><i class="fa-solid fa-key"></i></label>
            <input id="registerpw"
                   v-model="loginForm.password"
                   type="password"
                   placeholder="Password"
                   class="bg-transparent text-white p-2 border-gray-600 border-b-2 focus:border-gray-600 border-b-2 focus:outline-none w-full">
          </div>
          <div class="my-5 flex items-center gap-5 justify-center w-full">
            <label class="text-red-500 font-extrabold" for="registerpw2"><i class="fa-solid fa-key"></i></label>
            <input id="registerpw2"
                   v-model="registerForm.password2"
                   type="password"
                   placeholder="Confirm Password"
                   class="bg-transparent text-white p-2 border-gray-600 border-b-2 focus:border-gray-600 border-b-2 focus:outline-none w-full">
          </div>
        </div>


        <div class="flex justify-start mt-6">
          <button @click="submitRegister()"
                  type="button"
                  class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
            Register
          </button>
        </div>

        <p @click="toggle()" class="underline text-gray-400 mt-5 hover:cursor-pointer">Or login</p>

      </form>
    </div>

  </div>
</template>

<script>
export default {
  name: "LoginView.vue",
  data() {
    return {
      login: true,
      registerForm: {
        username: "",
        password: "",
        password2: "",
        email: "",
      },
      loginForm: {
        username: "",
        password: ""
      }
    }
  },
  methods: {
    toggle() {
      this.login = !this.login
    },
    submitLogin() {
      let base64 = btoa(JSON.stringify(this.loginForm))
      fetch(this.getAPIHostname() + "/user/",
          {
            method: "GET",
            mode: "cors",
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Basic ' + base64
            }
          }
      ).then(res => {
        if (res.status == 200){
          return res.json();
        }else if(res.status === 401) {
          this.$notify({
            title: "Error",
            text: "Invalid Login!",
            data: {
              type: 'error'
            }
          });
          localStorage.removeItem('auth')
          return;
        }
        else {
          this.$notify({
            title: "Error",
            text: " ",
            data: {
              type: 'error'
            }
          });
        }
      }).then(json => {
        if(!json){
          return;
        }
        localStorage.auth = base64;
        localStorage.user = JSON.stringify(json);
        this.$notify({
          title: "Success",
          text: "Successfully logged into OOC-Account",
          data: {
            type: 'success'
          }
        });
        setTimeout(function (){
          window.location.href = 'home';
        }, 1000)
      })

    },
    submitRegister() {

      if (this.registerForm.username.length < 3) {
        this.$notify({
          title: "Error",
          text: this.$t("login.error.length"),
          data: {
            type: 'error'
          }
        });
        return;
      }
      var re = /\S+@\S+\.\S+/;
      if (!re.test(this.registerForm.email)) {
        this.$notify({
          title: "Error",
          text: this.$t("login.error.email"),
          data: {
            type: 'error'
          }
        });
        return;
      }
      if (this.registerForm.password !== this.registerForm.password2) {
        this.$notify({
          title: "Error",
          text: this.$t("login.error.password"),
          data: {
            type: 'error'
          }
        });
        return;
      }

      fetch(this.getAPIHostname() + "/user/",
          {
            method: "POST",
            mode: "cors",
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(this.registerForm)
          }
      ).then(res => {
        if (res.status == 200) return res.json();
        else {
          this.$notify({
            title: "Error",
            text: " ",
            data: {
              type: 'error'
            }
          });
        }
      }).then(json => {
        let auth = {
          username: this.registerForm.username,
          password: this.registerForm.password
        }
        localStorage.auth = btoa(JSON.stringify(auth));
        localStorage.user = JSON.stringify(json);
        this.$notify({
          title: "Success",
          text: "",
          data: {
            type: 'success'
          }
        });
        setTimeout(function (){
          window.location.href = 'department';
        }, 1000)


      })

    }
  }
}
</script>

<style scoped>
.logincard{
  background: #001220        /* optional, center the image */

}
.img1{
  position: absolute;
  right: 0;
  top: 20%;
}

.img3{
  position: absolute;
  left: 0;
  top: 80%;
}

</style>
