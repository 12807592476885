<template>

  <notifications>
    <template #body="props">
      <div class="flex w-full max-w-sm mx-auto overflow-hidden  rounded-lg shadow-md bg-gray-900 mb-3">

        <div class="flex items-center justify-center w-12 bg-red-500" v-show="props.item.data.type === 'error'">
          <svg class="w-6 h-6 text-white fill-current" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
            <path d="M20 3.36667C10.8167 3.36667 3.3667 10.8167 3.3667 20C3.3667 29.1833 10.8167 36.6333 20 36.6333C29.1834 36.6333 36.6334 29.1833 36.6334 20C36.6334 10.8167 29.1834 3.36667 20 3.36667ZM19.1334 33.3333V22.9H13.3334L21.6667 6.66667V17.1H27.25L19.1334 33.3333Z"/>
          </svg>
        </div>

        <div class="flex items-center justify-center w-12 bg-emerald-500" v-show="props.item.data.type === 'success'">
          <svg class="w-6 h-6 text-white fill-current" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
            <path d="M20 3.33331C10.8 3.33331 3.33337 10.8 3.33337 20C3.33337 29.2 10.8 36.6666 20 36.6666C29.2 36.6666 36.6667 29.2 36.6667 20C36.6667 10.8 29.2 3.33331 20 3.33331ZM16.6667 28.3333L8.33337 20L10.6834 17.65L16.6667 23.6166L29.3167 10.9666L31.6667 13.3333L16.6667 28.3333Z"/>
          </svg>
        </div>

        <div class="flex items-center justify-center w-12 bg-blue-500" v-show="props.item.data.type === 'info'">
          <svg class="w-6 h-6 text-white fill-current" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
            <path d="M20 3.33331C10.8 3.33331 3.33337 10.8 3.33337 20C3.33337 29.2 10.8 36.6666 20 36.6666C29.2 36.6666 36.6667 29.2 36.6667 20C36.6667 10.8 29.2 3.33331 20 3.33331ZM21.6667 28.3333H18.3334V25H21.6667V28.3333ZM21.6667 21.6666H18.3334V11.6666H21.6667V21.6666Z"/>
          </svg>
        </div>


        <div class="px-4 py-2 -mx-3">
          <div class="mx-3">
            <span class="font-semibold  text-gray-400">{{ props.item.title }}</span>
            <p v-html="props.item.text" class="text-sm text-gray-200"></p>

          </div>
        </div>
      </div>

    </template>
  </notifications>

  <div v-show="$route.path !== '/' && !$route.path.startsWith('/user')">

    <SideBar @collapse="toggle" class="sidebar" ref="sidebar" />
    <TopBar @collapse="$refs.sidebar.toggleCollapsed()" />
    <div :class="[collapsed ? 'content collapsed' : 'content']">
      <router-view/>
    </div>
  </div>
  <div v-show="$route.path === '/' || $route.path.startsWith('/user')">
      <router-view/>
  </div>
  <!-- TODO footer -->
</template>


<script>
import SideBar from "@/components/SideBar";
import TopBar from "@/components/TopBar";

export default {
  components: {TopBar, SideBar},
  data() {
    return{
      collapsed: false
    }
  },
  methods: {
    toggle(val) {
      this.collapsed = val;
    }
  },
  mounted() {
    if(localStorage.user != null){
      let user = JSON.parse(localStorage.user);
      this.$i18n.locale = user.department.locale;
    }
  }
}
</script>

<style>

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');


::-webkit-scrollbar {
  width: 8px; /* 1px wider than Lion. */
  /* This is more usable for users trying to click it. */
  background-color: rgba(0,0,0,0);
  -webkit-border-radius: 100px;
}
/* hover effect for both scrollbar area, and scrollbar 'thumb' */
::-webkit-scrollbar:hover {
  background-color: rgba(0, 0, 0, 0.09);
}

/* The scrollbar 'thumb' ...that marque oval shape in a scrollbar */
::-webkit-scrollbar-thumb:vertical {
  /* This is the EXACT color of Mac OS scrollbars.
     Yes, I pulled out digital color meter */
  background: rgba(0,0,0,0.5);
  -webkit-border-radius: 100px;
}
::-webkit-scrollbar-thumb:vertical:active {
  background: rgba(0,0,0,0.61); /* Some darker color when you click it */
  -webkit-border-radius: 100px;
}

:root {
  --red: #FF4A57;
  --bg1: #23263a;
  --bg2: #1F2235;
  --bgCon: #1F2937;
}


.accent {
  color: var(--red)
}


.sidebar {
  position: fixed;
  top: 0;
}

body {
  color: white;
  background-color: #2a3239;
  font-family: 'Montserrat', sans-serif;
}

.content {
  margin-top: 2.5rem;
  margin-left: 5rem;
  padding: 2rem;
  position: relative;
  transition: 500ms;
}

@media screen and (min-width: 880px){
  .content{
    margin-left: 19rem;
  }
}

.content.collapsed {
  margin-left: 5rem;
}


.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: 2px solid var(--red);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.3rem;
  color: white;
  background-color: transparent;
  box-shadow: black;
  transition: 500ms;
}

.btn:hover {
  background-color: var(--red);
}

.btn.green {
  border: 2px solid #59CD90;
}

.btn.green:hover {
  background-color: #59CD90;
}

.btn.gray {
  border: 2px solid gray;
}

.btn.gray:hover {
  background-color: gray;
}

.card {
  background-color: var(--bgCon);
  min-width: 100%;
  width: fit-content;
  margin-bottom: 20px;
}
.card .title {
  padding: 1rem;
  font-size: 1rem;
  color: #bfbcbc;
}
.card .card-content {
  padding: 1rem;
}


.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.staticButton {
  z-index: 10;
  position: fixed;
  bottom: 3rem;
  right: 3rem;
  background-color: #59CD90;
  padding: 2rem;
  border-radius: 50%;
  transition: 500ms;
}

.staticButton.second {
  bottom: 9rem;
  background-color: #E0FF4F;
}

.staticButton:hover {
  transform: scale(1.2);
}

.unloaded {
  content: '';
  background: linear-gradient(270deg, rgba(75, 85, 99), rgba(75, 85, 99), rgba(75, 85, 99), rgb(123, 137, 156), rgba(75, 85, 99), rgba(75, 85, 99), rgba(75, 85, 99));
  background-size: 600% 600%;
  animation: MoveBackground 2s ease-in-out infinite;
  @apply w-3/4 visible rounded p-2
}

@keyframes MoveBackground {
  0% {
    background-position: 100% 100%
  }
  100% {
    background-position: 0% 100%
  }
}


.pagetitle{
  color: var(--red);
  font-size: 2rem;
  font-weight: bolder;
  padding-bottom: 1rem;
  padding-top: 0.5rem;
}

.newcard{
  background-color: var(--bg2);
  border-radius: 0.75rem;
}

.newcard .objectName{
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}
.newcard .objectValue{
  color: #bfbcbc;
}
.newcard .objectValueBlack{
  color: black;
  background-color: black;
  border-radius: 5px;
}

.newButton{
  background-color: var(--bg2);
  height: 3em;
  padding: 1rem;
  border-radius: 0.25em;
  transition: 100ms;
}
.newButton:hover{
  background-color: var(--bg1);
}

</style>
