<template>
  <AddCaseFileModal ref="modal"/>
  <button class="staticButton" @click="$refs.modal.show()"><i class="fa-solid fa-plus"></i></button>


  <div class="card">
    <div class="title-row">
      <div class="title">Fall-Akten</div>
      <form v-on:submit.prevent @submit="loadData">
        <input v-model="query" id="key" type="text"
               class="block w-full px-4 py-2 mt-2 text-gray-700 border border-gray-200 rounded-md bg-gray-800 text-gray-300 border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 focus:border-blue-300 focus:outline-none focus:ring"
               placeholder="Suche...">
      </form>
    </div>
    <hr>
    <div class="card-content">
      <div class="border-b border-gray-200 dark:border-gray-700">
        <ul class="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400">

          <li class="mr-2">
            <div href="#"
                 @click="this.selected = 'ALL'"
                 :class="[this.selected === 'ALL' ? 'inline-flex p-4 text-blue-600 rounded-t-lg border-b-2 border-blue-600 active dark:text-blue-500 dark:border-blue-500 group' : 'inline-flex p-4 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group']">
              Alle
            </div>
          </li>
          <li class="mr-2">
            <div href="#"
                 @click="this.selected = 'INVESTIGATIONS'"
                 :class="[this.selected === 'INVESTIGATIONS' ? 'inline-flex p-4 text-blue-600 rounded-t-lg border-b-2 border-blue-600 active dark:text-blue-500 dark:border-blue-500 group' : 'inline-flex p-4 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group']">
              Aktiv
            </div>
          </li>


          <li class="mr-2">
            <div href="#"
                 @click="this.selected = 'OPEN'"
                 :class="[this.selected === 'OPEN' ? 'inline-flex p-4 text-blue-600 rounded-t-lg border-b-2 border-blue-600 active dark:text-blue-500 dark:border-blue-500 group' : 'inline-flex p-4 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group']">
              Offen
            </div>
          </li>
          <li class="mr-2">
            <div href="#"
                 @click="this.selected = 'CLOSED'"
                 :class="[this.selected === 'CLOSED' ? 'inline-flex p-4 text-blue-600 rounded-t-lg border-b-2 border-blue-600 active dark:text-blue-500 dark:border-blue-500 group' : 'inline-flex p-4 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group']">
              Geschlossen
            </div>
          </li>
        </ul>
      </div>


      <div class="flex flex-col">
        <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="inline-block py-2 min-w-full sm:px-6 lg:px-8">
            <div class="overflow-hidden shadow-md sm:rounded-lg">

              <table class="min-w-full dark">
                <thead class=" bg-gray-700">
                <tr>
                  <th scope="col"
                      class="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-gray-400">
                    Name
                  </th>
                  <th scope="col"
                      class="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-gray-400">
                    Status
                  </th>
                  <th scope="col"
                      class="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-gray-400">
                    Einträge
                  </th>
                </tr>
                </thead>
                <tbody>

                <tr
                    class="border-b bg-gray-800 border-gray-700 transition hover:bg-red-500"
                    v-for="file in files"
                    :key="file.id"
                    v-show="this.selected === 'ALL' || (file.state === 'INVESTIGATIONS' && this.selected === 'INVESTIGATIONS') || (file.state === 'ACTIVE' && this.selected === 'OPEN') || (file.state === 'COMPLETED' && this.selected === 'CLOSED') || (file.state === 'CLOSED' && this.selected === 'CLOSED')"
                    @click="openFile(file.id)"
                >
                  <td class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap text-gray-400">
                    {{ file.name }}
                  </td>
                  <td class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap text-gray-400 key ">
                    {{ file.state }}
                  </td>
                  <td class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap text-gray-400">
                    {{ file.caseFileEntries.length }}
                  </td>
                </tr>


                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import AddCaseFileModal from "@/components/AddCaseFileModal";

export default {
  name: "CaseFilesView",
  components: {AddCaseFileModal},
  data() {
    return {
      selected: "INVESTIGATIONS",
      files: [],
      department: JSON.parse(localStorage.user).department,
      query: ""
    }
  },
  methods: {
    openFile(id) {
      window.location.href = 'casefile/' + id;
    },
    loadData() {
      fetch(this.getAPIHostname() + "/department/" + this.department.id + "/case/?query=" + this.query,
          {
            method: "GET",
            mode: "cors",
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Basic ' + localStorage.auth
            },
          }
      ).then(this.jsonParser)
          .then(json => this.files = json);
    }
  },
  mounted() {
    this.loadData();
  }
}
</script>

<style scoped>
.title-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title-row > *:last-child {
  margin: 1rem;
}

</style>
