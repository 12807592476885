<template>
  <div :class="[collapsed ? 'collapse' : '', ' flex fixed items-start self-start top-3 right-0 ease-in duration-200 z-50']">
    <div class="iconContainer rounded-l" @click="collapsed = !collapsed">
      <i class="fa-brands fa-discord"></i>
    </div>
    <div class="textContainer rounded-bl" v-html="$t('discord')"/>

  </div>
</template>

<script>
export default {
  name: "DiscordCard",
  data() {
    return {
      collapsed: true
    }
  }
}
</script>

<style scoped>


.iconContainer {
  background-color: #7289DA;
  @apply p-5
}

.textContainer {
  background-color: #99AAB5;
  width: 18rem;
  max-height: 70vh;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  @apply p-5
}

.textContainer::-webkit-scrollbar{
  display: none;
}

.collapse {

  margin-right: -18rem;
}
</style>
