export default {
  "host": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://api.lspdnet.com"])},
  "navbar": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akten"])},
    "wanted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wanted"])},
    "patrol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patrol"])},
    "laws": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Law management"])},
    "officers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Officer management"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
    "log": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logging"])},
    "cases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Case-Records"])},
    "stats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stats"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
    "deparmentsettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global Settings"])}
  },
  "ui": {
    "goback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["previous page"])}
  },
  "homeview": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wanted-list"])},
    "announcement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Announcement"])},
    "addannouncement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Announcement"])},
    "deleteannouncement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "wanted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wanted"])}
  },
  "filesview": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Records"])},
    "addModal": {
      "selector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crimes"])},
      "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fine"])},
      "prison": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prison sentence"])}
    }
  },
  "filestable": {
    "ttitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Records"])},
    "entries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Record entries"])},
    "names": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "birth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of birth"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
    "wanted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wanted?"])},
    "money": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fine (total)"])},
    "prison": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prison sentence (total)"])},
    "wantedStr": {
      "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
      "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["False"])}
    },
    "button": {
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Records"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add record entry"])}
    }
  },
  "patrolview": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patrol"])}
  },
  "fileview": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Record"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Height"])},
    "birth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of birth"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information"])}
  },
  "addfilemodal": {
    "addfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add File"])},
    "birth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birthdate"])},
    "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Height"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
    "abort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abort"])}
  },
  "lawview": {
    "addbtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])}
  },
  "ofcmanagement": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Officer"])},
    "addbtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Officer"])},
    "badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Badge-Number"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])}
  },
  "announcement": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Announcement"])},
    "announcement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Announcement"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
    "abort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abort"])}
  },
  "logview": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logs"])},
    "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
    "officer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Officer"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File"])}
  },
  "addlawbookmodal": {
    "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Law Book"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])}
  },
  "addlawmodal": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
    "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Law"])},
    "fine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fine"])},
    "prison": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prison"])}
  },
  "editcmodal": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Badge-Number"])},
    "abort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abort"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset password"])},
    "fire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fire"])}
  },
  "buttons": {
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
    "abort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abort"])}
  },
  "login": {
    "error": {
      "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username has to be at least 3 characters"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The email is invalid!"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The password is not identical!"])}
    }
  },
  "help": {
    "wanted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>This list contains all Citizens that have one or more Record-Entries which are flagged as wanted.</p>"])},
    "law": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Here you can add a Lawbook and add Laws to it. It will be listed when creating an entry to a police-record.</p>"])},
    "officerManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Here you can add officer accounts.<br>The password will be displayed when creating an account. You will only be able to reset it. <br>The groups do have to following permissions: </p><br><b>CHIEF</b><ul>  <li>- Redact a file</li></ul><br> <b>HIGH_COMMAND</b><ul>  <li>- View redacted content</li>  <li>- Edit laws</li></ul><br> <b>MID_COMMAND</b><ul>  <li>- Delete record-entries</li>  <li>- Create new officer accounts</li>  <li>- Edit officer accounts</li>  <li>- remove officer accounts</li></ul><br> <b>LOW_COMMAND</b><ul>  <li>- Edit Records</li></ul><br> <b>OFFICER</b><ul>  <li>- Normal permissions (create records, view records, etc...)</li></ul><br> <b>SUSPENDED</b><ul>  <li>- No permissions</li></ul>"])}
  },
  "discord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please join our Discord for any updates or import news! <br> If you've got any Problems or suggestens the Discordserver is the place to go!<br><br><a href='https://discord.gg/vAUcMggvdH'>https://discord.gg/vAUcMggvdH</a>"])}
}