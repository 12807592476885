<template>
<button @click="$router.go(-1)" class="pt-1 pb-2 text-gray-400 text-bold font-bold"><i class="fa-solid fa-arrow-left"></i> {{$t('ui.goback')}}</button>
</template>

<script>
export default {
  name: "GoBackButton"
}
</script>

<style scoped>

</style>
