<template>
<h1>Logging out...</h1>
</template>

<script>
export default {
  name: "LogoutView",
  mounted() {
    localStorage.removeItem("user")
    localStorage.removeItem("auth")
    window.location.href = "login";
  }
}
</script>

<style scoped>

</style>
