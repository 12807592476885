<template>
  <DiscordCard/>
  <AnnouceAddModule ref="announceAdd" @sent="getAnnouncements"/>
  <div class="flex mb-5 gap-5 w-100">
  <div class="grow w-1/2">

    <div class="flex justify-between w-full flex-wrap">
      <h4 class="pagetitle">{{ $t('homeview.announcement') }}</h4>
      <div class="flex gap-2">
        <button v-show="this.$hasPerm('ANNOUNCEMENT_ADD')" @click="openAddAnnouncement()" class="newButton">
          <i class="fa-solid fa-pen-to-square"></i> {{ $t('homeview.addannouncement') }}
        </button>
      </div>
    </div>

    <div class="newcard p-5 w-full mt-1">
      <div class="flex flex-col">
        <div class="flex flex-col gap-5">
          <div v-for="announcement in announcements" :key="announcement.id">
            <div class="flex justify-between">
              <div class="font-bold text-xl">{{ announcement.title }}</div>
              <div class="flex gap-2">
                <button v-show="this.$hasPerm('ANNOUNCEMENT_REMOVE')"
                        @click="deleteAnnouncement(announcement)"
                        class="newButton text-red-500">
                  <i class="fa-solid fa-times"></i> {{ $t('homeview.deleteannouncement') }}
                </button>
              </div>
            </div>
            <div class="flex flex-col gap-5">
              <div class="objectValue ql-editor" v-html="announcement.message"></div>
              <span>{{ toDate(announcement.timestamp) }}, {{ announcement.author.name }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <div class="grow w-1/2">

    <div class="flex justify-between w-full">
      <h4 class="pagetitle">{{ $t('homeview.wanted') }}</h4>
    </div>

    <div class="newcard p-5 w-full">
        <div class="flex flex-col gap-5">
          <div v-for="file in wanted" :key="file.id" class="flex gap-1 items-center justify-between">
            <img class="h-16 w-16 rounded-lg" :src="file.imgLink">
            <span>{{file.name}}</span>
            <span>Tel.: {{file.telnumber}}</span>
            <router-link :to="'/file/' + file.id" class="newButton"> <i class="fa-solid fa-eye"></i> View</router-link>
          </div>
        </div>
    </div>

  </div>
  </div>

</template>

<script>
import DiscordCard from "@/components/DiscordCard";
import AnnouceAddModule from "@/components/announcement/AnnouncementAddModule";

export default {
  name: "HomeView",
  components: {AnnouceAddModule, DiscordCard},
  data() {
    return {
      department: JSON.parse(localStorage.user).department,
      user: JSON.parse(localStorage.user),
      announcements: [
        {
          id: -1,
          author: {
            badgeNumber: "0",
            name: "Loading...",
          },
          title: "Loading...",
          message: "Work in Progess....!",
          timestamp: 1661439013793
        },
      ],
      wanted: [
        {
          name: "Loading...",
          imgLink: "https://avatars.githubusercontent.com/u/35440139",
          id: -1,
          birthdate: "NaN",
          telnumber: "NaN",
        },
        {
          name: "Loading...",
          imgLink: "https://avatars.githubusercontent.com/u/35440139",
          id: -1,
          birthdate: "NaN",
          telnumber: "NaN",
        }
      ]
    }
  },
  methods: {
    openAddAnnouncement() {
      this.$refs.announceAdd.show();
    },
    getAnnouncements() {
      fetch(this.getAPIHostname() + "/department/" + this.department.id + "/announcement/",
          {
            method: "GET",
            mode: "cors",
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Basic ' + localStorage.auth
            },
          }
      ).then(this.jsonParser).then(json => {
        this.announcements = json;
      })
    },
    getWanted() {
      fetch(this.getAPIHostname() + "/department/" + this.department.id + "/file/topwanted",
          {
            method: "GET",
            mode: "cors",
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Basic ' + localStorage.auth
            },
          }
      ).then(this.jsonParser).then(json => {
        this.wanted = json;
      })
    },
    toDate(millies) {
      console.log(millies)
      return new Date(millies).toLocaleString()
    },
    deleteAnnouncement(announcement) {
      fetch(this.getAPIHostname() + "/department/" + this.department.id + "/announcement/" + announcement.id,
          {
            method: "DELETE",
            mode: "cors",
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Basic ' + localStorage.auth
            },
          }
      ).then(value => {
        if(value.status == 200){
          console.log("Deleted Announcement");
          this.getAnnouncements();
        }
      })
    }
  },
  mounted() {
    this.getAnnouncements();
    this.getWanted();
  }
}
</script>

<style scoped>
</style>
