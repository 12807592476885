<template class="landing">

  <img class="img1" src="https://uploads-ssl.webflow.com/6003416b0aa4a84bf3bfbd91/6003416b0aa4a81b2bbfbe3b_rec3.png" alt="img1">
  <img src="https://uploads-ssl.webflow.com/6003416b0aa4a84bf3bfbd91/6003416b0aa4a80956bfbe3d_rec1.png" alt="img3" class="img3">

  <!-- Navbar-->
  <div class="bg-transparent pl-10 pr-10 pt-5 absolute w-full flex justify-between items-center top-0 left-0">
    <a class="text-white text-5xl font-extrabold" href="#"><img src="@/assets/img/Textlogo-Icon.svg" width="350" alt=""></a>

    <a class="btn" href="https://lspdnet.com/login">Zum Computer</a>
  </div>

  <!-- Start-->

  <div class="text-center mt-52 pl-20 pr-20 flex flex-col gap-5 items-center h-screen">
    <h1 class="heading-8 font-bold text-6xl z-20">Der Polizeicomputer für deinen Roleplay Server!</h1>
    <a class="btn2 w-min z-20" href="#features">Features</a>

    <img src="https://uploads-ssl.webflow.com/6003416b0aa4a84bf3bfbd91/6003416b0aa4a86f0abfbe3c_sli1.png" alt="img2" class="img2">
    <img class="img4 z-0" src="@/assets/img/files.png" alt="bunch">
  </div>


  <div id="features" class="text-center pl-32 pr-32">
    <h1 class="font-extrabold text-5xl mb-3">Features</h1>
    <div class="grid grid-rows-2 grid-flow-col gap-10">

      <div class="flex flex-col gap-4 items-center">
        <img src="@/assets/img/akte.png" alt="" height="100" width="100">
        <h2 class="text-xl font-bold">Akten</h2>
        <p>
          LSPDNet ermöglicht es dir ganz einfach Akten für Mitspieler zu erstellen, und dort
          Akteneinträge mit einstellbaren Strafen zu erstellen, diese als eine Fahndung zu markieren
          oder zu schwärzen
        </p>
      </div>
      <div class="flex flex-col gap-4 items-center">
        <img src="@/assets/img/chart.png" alt="" height="100" width="100">
        <h2 class="text-xl font-bold">Berechtigungen</h2>
        <p>
          Verwalte alle Angestellten deines Departments bestimme deren Berechtigungen.
          Erstelle dafür eigene Gruppen mit selbst einstellten Berechtigungen.
        </p>
      </div>
      <div class="flex flex-col gap-4 items-center">
        <img src="@/assets/img/graphs.png" alt="" height="100" width="100">
        <h2 class="text-xl font-bold">Statistiken & Logs</h2>
        <p>
          Das LSPDNet ermöglicht es, sich Statistiken über die aktivsten Officer einzusehen.
          Dadruch sind Beförderungen nach Leistung möglich. Ebenfalls lassen sich umfangreiche Logs einsehen,
          um nach zu vollziehen wer eine Akte verändert hat.
        </p>
      </div>
      <div class="flex flex-col gap-4 items-center">
        <img src="@/assets/img/pay.png" alt="" height="100" width="100">
        <h2 class="text-xl font-bold">Kostenlos</h2>
        <p>
          LSPDNet ist aktuell komplett uneingeschränkt kostenlos verfügbar. In der Zukunft könnten
          neue Funktionen für kostenlose Nutzer gesperrt werden.
        </p>
      </div>
      <div class="flex flex-col gap-4 items-center">
        <img src="@/assets/img/discord.png" alt="" height="100" width="100">
        <h2 class="text-xl font-bold">Discord-Bot</h2>
        <p>
          Über unsere Discord-Einbindung kannst du direkt mit einem Befehl, Zugangsdaten für deine Mitarbeiter erstellen.
        </p>
      </div>
      <div class="flex flex-col gap-4 items-center">
        <img src="@/assets/img/pc.png" alt="" height="100" width="100">
        <h2 class="text-xl font-bold">Streifen-System</h2>
        <p>
          Behalte alle aktuellen Streifen im Blick und schenke deiner Leitstelle Übersicht!
        </p>
      </div>
    </div>
  </div>

  <div id="screens" class="text-center pl-32 pr-32 mt-20">
    <h1 class="font-extrabold text-5xl mb-3">Screenshots</h1>
    <!-- Slideshow container -->
    <div class="slideshow-container">


      <center>
      <div v-show="slideIndex === key" v-for="(img,key) in imgs" :key="key" class="mySlides fade">
        <div class="numbertext">{{key + 1}} / {{imgs.length}}</div>
        <img :src="img" style="width:100%">
      </div>
      </center>

      <!-- Next and previous buttons -->
      <a class="prev" @click="plusSlides(-1)">&#10094;</a>
      <a class="next" @click="plusSlides(1)">&#10095;</a>
    </div>
    <br>

    <!-- The dots/circles -->
    <div style="text-align:center">
      <span v-for="(img,key) in imgs" :key="key" :class="['dot', slideIndex === key ? 'active' : '']" @click="currentSlide(key)"></span>
    </div>

  </div>
  <div id="footer" class="mt-64 mb-10 flex flex-col justify-center items-center gap-10">

    <h1 class="text-2xl">LSPDNet</h1>

    <div class="flex justify-between gap-20">
      <a href="https://dash.primeapi.de/imprint">Imprint</a>
      <a href="https://discord.gg/lspdnet">Discord</a>
      <a href="https://lspdnet.com">LSPDNet</a>
      <a href="https://primeapi.de">PrimeAPI</a>
    </div>

    <p>Made with ❤️ by PrimeAPI</p>
  </div>

</template>

<script>
export default {
  name: "LandingView",
  data(){
    return{
      slideIndex: 0,
      imgs: [
        "http://img.primeapi.de/2022/10/26/lXMN",
        "http://img.primeapi.de/2022/10/26/ipQq",
          "http://img.primeapi.de/2022/10/26/gOLe",
          "http://img.primeapi.de/2022/10/26/Vp1f",
          "http://img.primeapi.de/2022/10/26/KKuh",
          "http://img.primeapi.de/2022/10/26/OpU6",
          "http://img.primeapi.de/2022/10/26/l52F",
          "http://img.primeapi.de/2022/10/26/9z43",
          "http://img.primeapi.de/2022/10/26/5Hu7"
      ]
    }
  },
  methods: {
    plusSlides(n) {
      if(this.slideIndex + n >= this.imgs.length) this.slideIndex = 0
      else if (this.slideIndex + n< 0) this.slideIndex = this.imgs.length - 1
      else this.slideIndex += n
    },
    currentSlide(n) {
      if(n >= 0 && n < this.imgs.length)
      this.slideIndex = n
    },
  }
}
</script>

<style scoped>

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
::-webkit-scrollbar {
  width: 8px; /* 1px wider than Lion. */
  /* This is more usable for users trying to click it. */
  background-color: rgba(0,0,0,0);
  -webkit-border-radius: 100px;
}
/* hover effect for both scrollbar area, and scrollbar 'thumb' */
::-webkit-scrollbar:hover {
  background-color: rgba(0, 0, 0, 0.09);
}
/* The scrollbar 'thumb' ...that marque oval shape in a scrollbar */
::-webkit-scrollbar-thumb:vertical {
  /* This is the EXACT color of Mac OS scrollbars.
     Yes, I pulled out digital color meter */
  background: rgba(0,0,0,0.5);
  -webkit-border-radius: 100px;
}
::-webkit-scrollbar-thumb:vertical:active {
  background: rgba(0,0,0,0.61); /* Some darker color when you click it */
  -webkit-border-radius: 100px;
}


.landing {
  color: white;
  background-image: linear-gradient(180deg, #020312, #02031d);
  font-family: 'Roboto', sans-serif;
  scroll-behavior: smooth;
}

.btn{
  padding: 1rem;
  border: 1px solid gray;
  border-radius: 20rem;
  color: white;
  font-size: 1.25rem;
  background-color: transparent;
}


.btn:hover{
  background: linear-gradient(270deg,#e9516e,#f2957e);
  color: black;
}


.btn2{
  padding: 1rem;
  border: 1px solid gray;
  border-radius: 20rem;
  font-size: 1.25rem;
  background: linear-gradient(270deg,#e9516e,#f2957e);
  color: white;
  box-shadow: 0 4px 26px 0 rgb(233 81 110 / 58%);
  font-weight: bold;
}

.heading-8 {
  max-width: 733px;
  background-image: linear-gradient(252deg,#e9516e,#f6b8c4 33%,#fff);
  font-size: 74px;
  line-height: 90px;
  text-align: center;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}


.img1{
  position: absolute;
  right: 0;
  top: 20%;
}
.img2{
  transform: translateX(-8rem);
}

.img4{
  position: absolute;
  height: 30rem;
  width: 30rem;
  right: 0;
  top:22rem
}


.img3{
  position: absolute;
  left: 0;
  top: 150%;
}

.slideshow-container{
  position: relative;
  height: fit-content;
  width: fit-content;
  border-radius: 1rem;
  overflow: hidden;
}

.prev{
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  font-size: 2rem;
  color: white;
  cursor: pointer;
  padding: 0.5rem;
  transition: 500ms;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.prev:hover{
  background-color: rgba(0, 0, 0, 0.7);
}


.next{
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  font-size: 2rem;
  color: white;
  cursor: pointer;
  padding: 0.5rem;
  transition: 500ms;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.next:hover{
  background-color: rgba(0, 0, 0, 0.7);
}

.mySlides{
  position: relative;
}

.numbertext{
  position: absolute;
  top: 0.25rem;
  left: 0.25rem;
  font-size: 0.75rem;
}

.text {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
}
/* The dots/bullets/indicators */
.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.active, .dot:hover {
  background-color: #717171;
}


</style>
