<template>
  <div v-show="showModal" class="fixed z-20 inset-0 overflow-y-auto " aria-labelledby="modal-title" role="dialog"
       aria-modal="true">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 ">
      <div class="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
      <div id="popup"
           class="inline-block align-bottom dark bg-gray-800 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
        <div class=" px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div class="sm:flex sm:items-start">
            <div
                class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-gray-900 sm:mx-0 sm:h-10 sm:w-10">
              <i class="fa fa-plus-circle"></i>
            </div>
            <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <h3 class="text-lg leading-6 font-medium " id="modal-title">Permission-Gruppe</h3>
              <div class="mt-2">
                <div class="flex flex-col mt-8 space-y-3 sm:space-y-0 sm:flex-row sm:justify-start sm:-mx-2">

                  <form>
                    <div class="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">

                      <div>
                        <label class="text-gray-200" for="badge">Gruppen-Name</label>
                        <input id="badge"
                               v-model="object.name"
                               type="text"
                               class="block w-full px-4 py-2 mt-2 text-gray-700 border border-gray-200 rounded-md bg-gray-800 text-gray-300 border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 focus:border-blue-300 focus:outline-none focus:ring">
                      </div>

                      <div>
                        <label class="text-gray-200" for="prison">Sortierung</label>
                        <input id="prison"
                               v-model="object.sort"
                               type="number"
                               class="block w-full px-4 py-2 mt-2 text-gray-700 border border-gray-200 rounded-md bg-gray-800 text-gray-300 border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 focus:border-blue-300 focus:outline-none focus:ring">
                      </div>
                    </div>

                      <div class="mt-4">
                        <label class="text-gray-200">Berechtigungen</label> <br>
                        <table class="min-w-full dark mt-1">
                          <thead class=" bg-gray-700">
                          <tr>
                            <th scope="col"
                                class="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-gray-400">
                              Name
                            </th>
                            <th scope="col"
                                class="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-gray-400">
                              Aktiviert
                            </th>
                          </tr>
                          </thead>
                          <tbody>

                          <tr
                              class="border-b bg-gray-800 border-gray-700"
                              v-for="permission in permissionsList" :key="permission"
                              @click="object.permissionType.includes(permission) ? removeItemOnce(object.permissionType, permission) : object.permissionType.push(permission)">

                            <td :class="['py-4 px-6 text-sm text-gray-500 whitespace-nowrap']">
                              <i v-show='permission === "OFFICER_EDIT_EDIT_RANK" || permission === "PERMISSION_EDIT"'
                                 class="fa-solid fa-circle-exclamation text-red-500"></i> {{
                                $t("permission." + permission)
                                                                                          }}
                            </td>
                            <td :class="['py-4 px-6 text-sm text-gray-500 whitespace-nowrap']">
                              <span v-show="object.permissionType.includes(permission)">
                                <i class="fa fa-check-circle text-green-500"></i>
                              </span>
                              <span v-show="!object.permissionType.includes(permission)">
                                <i class="fa fa-circle-xmark text-red-500"></i>
                              </span>
                            </td>
                          </tr>

                          </tbody>
                        </table>


                      </div>

                      <div class="mt-4">
                        <label class="text-gray-200">Standard Gruppe?</label> <br>
                        <label class="switch mt-1">
                          <input type="checkbox" v-model="defaultGroup">
                          <span class="slider"></span>
                        </label>
                      </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <button @click="close" type="button"
                  class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2  text-base font-medium hover:text-gray-900  hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
            {{ $t('buttons.abort') }}
          </button>
          <button @click="clicked" type="button"
                  class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm">
            {{ $t('buttons.submit') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: "PermissionGroupModal",
  components: {},
  data() {
    return {
      showModal: false,
      department: JSON.parse(localStorage.user).department,
      object: {
        name: "",
        sort: 0,
        permissionType: []
      },
      defaultGroup: false,
      permissionsList: [
        "ANNOUNCEMENT_ADD",
        "ANNOUNCEMENT_REMOVE",
        "CASEFILE_ADD",
        "CASEFILE_VIEW",
        "CASEFILE_CONFIDENTIAL_VIEW",
        "CASEFILE_CONFIDENTIAL_EDIT",
        "OFFICER_EDIT_CREATE",
        "OFFICER_EDIT_DELETE",
        "OFFICER_EDIT_EDIT_PASSWORD",
        "OFFICER_EDIT_EDIT_RANK",
        "OFFICER_VIEW",
        "PERMISSION_EDIT",
        "FILE_VIEW",
        "FILE_CREATE",
        "FILE_EDIT",
        "FILE_DELETE",
        "FILE_CONFIDENTIAL_EDIT",
        "FILE_CONFIDENTIAL_VIEW",
        "FILE_ENTRY_CREATE",
        "FILE_ENTRY_DELETE",
        "FILE_ENTRY_EDIT",
        "LAW_VIEW",
        "LAW_ADD",
        "LAW_DELETE",
        "LOG_VIEW",
        "PATROL_CONTROLLER",
        "PATROL_USE",
        "PATROL_EDIT_PATROL",
        "PATROL_EDIT_CAR",
        "PATROL_EDIT_CODE",
        "STATS_VIEW",
        "GLOBALSETTINGS_DISCORD"
      ],
      displayedList: [],
    }
  },
  methods: {
    show(object) {
      this.object = object;
      this.showModal = true;
      this.defaultGroup = this.object.id === this.department.defaultGroup.id
    },
    close() {
      this.showModal = false
    },
    clicked() {
      fetch(this.getAPIHostname() + "/department/" + this.department.id + "/permissions/?defaultGroup=" + this.defaultGroup,
          {
            method: "POST",
            mode: "cors",
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Basic ' + localStorage.auth
            },
            body: JSON.stringify(this.object)
          }
      ).then(res => {
        if (res.status == 200) {
          this.$emit('sent')
          this.close();
        } else {
          this.$notify({
            title: "Error",
            text: " ",
            data: {
              type: 'error'
            }
          });
        }
      })

    },
    prepareList() {
      this.displayedList = [];
      for (let s in this.permissionsList) {
        if (!this.object.permissionType.includes(this.permissionsList[s])) {
          this.displayedList.push(this.permissionsList[s])
        }
      }
    },
    removeItemOnce(arr, value) {
      var index = arr.indexOf(value);
      if (index > -1) {
        arr.splice(index, 1);
      }
      return arr;
    }
  }
}
</script>

<style scoped>

</style>
