<template>
  <AddCaseFileEntryModal ref="modal" @sent="loadData"/>
  <AddCaseFileModal ref="edit" @sent="loadData" />
  <button class="staticButton" @click="$refs.modal.show()"><i class="fa-solid fa-plus"></i></button>
  <button class="staticButton second" @click="$refs.edit.edit(this.case)"><i class="fa-solid fa-pen"></i></button>

  <div class="smallcards">
    <InfoCard
        title="Akte"
        :content="this.case.name"
        icon="fa-users"
        color="#DB5461"></InfoCard>
    <InfoCard
        title="Status"
        :content="this.case.state"
        icon="fa-file"
        color="#246EB9"></InfoCard>
  </div>
  <div class="card relative">
    <img v-show="this.case.confidential" class="absolute z-10" src="@/assets/confidential.png" height="150" width="300" style="top: -5%; left: -5%">
    <div class="title">Einträge</div>
    <hr>
    <div class="card-content">

      <div
      v-for="entry in this.case.caseFileEntries"
      :key="entry.id"
      :class="[entry.important ? 'bg-gradient-to-r from-red-500 to-gray-500' : '','relative', this.case.confidential && !(this.user.permission === 'CHIEF' || this.user.permission === 'HIGH_COMMAND') ? 'bg-black text-black rounded' : '']"
      @click="openEdit(entry)"
      >
        <div class="ql-editor m-5" v-html="entry.content" />
        <div class="flex gap-3 absolute right-0 bottom-0 p-2">
          <div>{{toDate(entry.timestamp)}}</div>
          <div>Officer: {{entry.copUser.badgeNumber + "-" + entry.copUser.name}}</div>
        </div>
        <hr>
      </div>

    </div>
  </div>

</template>

<script>
import AddCaseFileEntryModal from "@/components/AddCaseFileEntryModal";
import InfoCard from "@/components/InfoCard";
import AddCaseFileModal from "@/components/AddCaseFileModal";

export default {
  name: "CaseView",
  components: {AddCaseFileModal, InfoCard, AddCaseFileEntryModal},
  data() {
    return {
      department: JSON.parse(localStorage.user).department,
      case: {},
      user: JSON.parse(localStorage.user),
    }
  },
  methods: {
    toDate(millies){
      return new Date(millies).toLocaleString()
    },
    loadData() {
      fetch(this.getAPIHostname() + "/department/" + this.department.id + "/case/" + this.$route.params.id,
          {
            method: "GET",
            mode: "cors",
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Basic ' + localStorage.auth
            },
          }
      )
          .then(this.jsonParser)
          .then(json => this.case = json)
    },
    openEdit(o){
      if(!this.case.confidential || this.user.permission === 'CHIEF' || this.user.permission === 'HIGH_COMMAND') {
        this.$refs.modal.edit(o)
      }
    }
  },
  mounted() {
    this.loadData();
  }
}
</script>

<style scoped>

</style>
